import React, { useContext } from "react";
import Row from "./Row/Row";
import { VisitContext } from "./VisitContext";

import GlobalContext from "../../../../../hooks/GlobalContext";
import PatientTrackingContext from "./PatientTrackingContext";

export default function(props) {
    const { localStore } = useContext(GlobalContext);
    const patientTracking = useContext(PatientTrackingContext);
    const { visits, stages } = patientTracking;

    const sortedVisits = visits
        .filter(visit => {
            const currentStageId = getVisitCurrentStageId(visit);

            return !Object.keys(stages).includes(currentStageId);
        })
        .sort((a, b) => {
            return a.createdTime < b.createdTime;
        });

    function getVisitCurrentStageId(visit) {
        const stageId =
            visit.stageHistory[visit.stageHistory.length - 1].stageId;
        return stageId;
    }

    if (sortedVisits.length < 1) {
        return null;
    }
    return (
        <React.Fragment>
            <div>
                {localStore.data.showPatientTrackingStageHeaders && (
                    <div
                        style={{
                            backgroundColor: "grey",
                            color: "black",
                            textAlign: "center",
                            borderBottom: "1px solid black",
                            fontWeight: "bold"
                        }}
                    >
                        {"??"}
                    </div>
                )}
                {sortedVisits.map(visit => {
                    return (
                        <VisitContext.Provider key={visit.id} value={visit}>
                            <Row></Row>
                        </VisitContext.Provider>
                    );
                })}
            </div>
        </React.Fragment>
    );
}
