import synthTemplates from "./synthTemplates";
import noteSequences from "./noteSequences";

const rootFreq = 440;

export default {
    s0: {
        parts: [
            {
                noteSequence: noteSequences["ding"],
                synthTemplate: synthTemplates["st2"]
            },
            {
                noteSequence: noteSequences["ding"].map(note => {
                    return { ...note, rootFrequency: note.rootFrequency * 4 };
                }),
                synthTemplate: synthTemplates["st1"]
            },
            {
                noteSequence: noteSequences["ding"].map(note => {
                    return { ...note, rootFrequency: note.rootFrequency / 2 };
                }),
                synthTemplate: synthTemplates["st4"]
            }
        ],
        tempoUnit: 0.04
    },
    s1: {
        parts: [
            {
                noteSequence: noteSequences["ns1"],
                synthTemplate: synthTemplates["st2"]
            },
            {
                noteSequence: noteSequences["ns1"].map(note => {
                    return { ...note, rootFrequency: note.rootFrequency / 2 };
                }),
                synthTemplate: synthTemplates["st1"]
            }
        ],
        tempoUnit: 0.1
    },
    s2: {
        parts: [
            {
                noteSequence: noteSequences["ns2"],
                synthTemplate: synthTemplates["st1"]
            },
            {
                noteSequence: noteSequences["ns2A"],
                synthTemplate: synthTemplates["st2"]
            }
        ],
        tempoUnit: 0.02
    },
    s3: {
        parts: [
            {
                noteSequence: noteSequences["ns3"],
                synthTemplate: synthTemplates["st1"]
            },
            {
                noteSequence: noteSequences["ns3"],
                synthTemplate: synthTemplates["st2"]
            }
        ],
        tempoUnit: 0.02
    },
    s4: {
        parts: [
            {
                noteSequence: noteSequences["crain"],
                synthTemplate: synthTemplates["st2"]
            },
            {
                noteSequence: noteSequences["crain"],
                synthTemplate: synthTemplates["st4"]
            }
        ],
        tempoUnit: 0.1
    },

    s5: {
        parts: [
            {
                noteSequence: (() => {
                    let ns = [];
                    for (let i = 1; i < 6; i++) {
                        ns.push({
                            duration: 1 / i,
                            rootFrequency: rootFreq / 2,
                            frequencyRatio: i + 1 / i
                        });
                    }
                    for (let i = 5; i > 0; i--) {
                        ns.push({
                            duration: 1 / i,
                            rootFrequency: rootFreq / 2,
                            frequencyRatio: i + 1 / i
                        });
                    }
                    return ns;
                })(),
                synthTemplate: synthTemplates["st1"]
            },
            {
                noteSequence: (() => {
                    let ns = [];
                    ns.push({
                        duration: 1,
                        rootFrequency: rootFreq / 2,
                        frequencyRatio: 0
                    });
                    for (let i = 1; i < 6; i++) {
                        ns.push({
                            duration: 1 / i,
                            rootFrequency: rootFreq / 2,
                            frequencyRatio: i + 1 / i
                        });
                    }
                    for (let i = 5; i > 0; i--) {
                        ns.push({
                            duration: 1 / i,
                            rootFrequency: rootFreq / 2,
                            frequencyRatio: i + 1 / i
                        });
                    }

                    return ns;
                })(),
                synthTemplate: synthTemplates["st2"]
            },
            {
                noteSequence: (() => {
                    let ns = [];
                    ns.push({
                        duration: 2,
                        rootFrequency: rootFreq / 2,
                        frequencyRatio: 0
                    });
                    for (let i = 1; i < 6; i++) {
                        ns.push({
                            duration: 1 / i,
                            rootFrequency: rootFreq / 2,
                            frequencyRatio: i + 1 / i
                        });
                    }
                    for (let i = 5; i > 0; i--) {
                        ns.push({
                            duration: 1 / i,
                            rootFrequency: rootFreq / 2,
                            frequencyRatio: i + 1 / i
                        });
                    }

                    return ns;
                })(),
                synthTemplate: synthTemplates["st3"]
            }
        ],
        tempoUnit: 0.1
    },
    s6: {
        parts: [
            {
                noteSequence: noteSequences["ns4"],
                synthTemplate: synthTemplates["st2"]
            }
        ],
        tempoUnit: 0.1
    },
    s7: {
        parts: [
            {
                noteSequence: noteSequences["arp1"],
                synthTemplate: synthTemplates["st1"]
            },
            {
                noteSequence: noteSequences["arp1"].map(note => {
                    return {
                        ...note,
                        rootFrequency: note.rootFrequency * (1 / 3)
                    };
                }),
                synthTemplate: synthTemplates["st2"]
            },
            {
                noteSequence: noteSequences["arp1"]
                    .map(note => {
                        return {
                            ...note,
                            rootFrequency: note.rootFrequency * (2 / 3)
                        };
                    })
                    .reverse(),
                synthTemplate: synthTemplates["st2"]
            }
        ]
    },
    s8: {
        parts: [
            {
                noteSequence: (() => {
                    let ns = [];
                    for (let i = 1; i < 10; i++) {
                        ns.push({
                            duration: 1 / i,
                            rootFrequency: rootFreq / 2,
                            frequencyRatio: i / (i + 1)
                        });
                    }

                    return ns;
                })(),
                synthTemplate: synthTemplates["sine"]
            },
            {
                noteSequence: (() => {
                    let ns = [];
                    for (let i = 1; i < 10; i++) {
                        ns.push({
                            duration: 1 / i,
                            //   rootFrequency: -Math.cos(i) * 2 + rootFreq,
                            rootFrequency: rootFreq,
                            //   frequencyRatio: 1
                            frequencyRatio: i / (i + 1)
                        });
                    }

                    return ns;
                })(),
                synthTemplate: synthTemplates["st4"]
            }
        ],
        tempoUnit: 0.08
    },
    s9: {
        parts: [
            {
                noteSequence: noteSequences["ns6"],
                synthTemplate: synthTemplates["st2"]
            },
            {
                noteSequence: noteSequences["ns7"].map(note => {
                    return { ...note, rootFrequency: note.rootFrequency / 2 };
                }),
                synthTemplate: synthTemplates["sine"]
            }
        ],
        tempoUnit: 0.1
    },
    s10: {
        parts: [
            {
                noteSequence: noteSequences["ns8"],
                synthTemplate: synthTemplates["st3"]
            },
            {
                noteSequence: noteSequences["ns9"],
                synthTemplate: synthTemplates["st2"]
            }
        ],
        tempoUnit: 0.1
    },
    s11: {
        parts: [
            {
                noteSequence: noteSequences["ns10"],
                synthTemplate: synthTemplates["st1"]
            },
            {
                noteSequence: noteSequences["ns10"],
                synthTemplate: synthTemplates["st2"]
            },
            {
                noteSequence: noteSequences["ns11"].map(note => {
                    return {
                        ...note,
                        rootFrequency: note.rootFrequency * (4 / 5)
                    };
                }),
                synthTemplate: synthTemplates["st1"]
            }
        ],
        tempoUnit: 0.1
    },
    s12: {
        parts: [
            {
                noteSequence: noteSequences["ns12"],
                synthTemplate: synthTemplates["st2"]
            },
            {
                noteSequence: noteSequences["ns13"].map(note => {
                    return {
                        ...note,
                        rootFrequency: note.rootFrequency * (1 / 3)
                    };
                }),
                synthTemplate: synthTemplates["st4"]
            }
        ],
        tempoUnit: 0.06
    },
    s13: {
        parts: [
            {
                noteSequence: noteSequences["ns14"].map(note => {
                    return { ...note, rootFrequency: note.rootFrequency * 2 };
                }),
                synthTemplate: synthTemplates["st2"]
            },
            {
                noteSequence: noteSequences["ns15"].map(note => {
                    return { ...note, rootFrequency: note.rootFrequency };
                }),
                synthTemplate: synthTemplates["st2"]
            },
            {
                noteSequence: noteSequences["ns16"].map(note => {
                    return { ...note, rootFrequency: note.rootFrequency / 2 };
                }),
                synthTemplate: synthTemplates["st2"]
            }
        ],
        tempoUnit: 0.07
    },
    s14: {
        parts: [
            {
                noteSequence: noteSequences["z1"],
                synthTemplate: synthTemplates["st2"]
            },
            {
                noteSequence: noteSequences["z1"],
                synthTemplate: synthTemplates["st4"]
            }
        ],
        tempoUnit: 0.1
    },
    s15: {
        parts: [
            {
                noteSequence: noteSequences["m1"],
                synthTemplate: synthTemplates["st2"]
            },
            {
                noteSequence: noteSequences["m1"].map(note => {
                    return { ...note, rootFrequency: note.rootFrequency / 8 };
                }),
                synthTemplate: synthTemplates["st3"]
            }
        ],
        tempoUnit: 0.1
    },
    s16: {
        parts: [
            {
                noteSequence: noteSequences["m2"],
                synthTemplate: synthTemplates["sine"]
            },
            {
                noteSequence: noteSequences["m2"].map((note, index) => {
                    if (index === 0) {
                        return { ...note, duration: note.duration + 0.1 };
                    } else {
                        return note;
                    }
                }),
                synthTemplate: synthTemplates["s4"]
            }
        ],
        tempoUnit: 0.1
    },
    s17: {
        parts: [
            {
                noteSequence: noteSequences["ns5"],
                synthTemplate: synthTemplates["st4"]
            },
            {
                noteSequence: noteSequences["ns5"].map(note => {
                    return {
                        ...note,
                        rootFrequency: (note.rootFrequency / 2) * (6 / 5)
                    };
                }),
                synthTemplate: synthTemplates["st4"]
            },
            {
                noteSequence: noteSequences["ns5"].map(note => {
                    return {
                        ...note,
                        rootFrequency: (note.rootFrequency / 4) * (8 / 5)
                    };
                }),
                synthTemplate: synthTemplates["sine"]
            },
            {
                noteSequence: noteSequences["ns5"].map(note => {
                    return {
                        ...note,
                        rootFrequency: (note.rootFrequency / 4) * (6 / 5)
                    };
                }),
                synthTemplate: synthTemplates["sine"]
            }
        ],
        tempoUnit: 0.1
    }
};
