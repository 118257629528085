import React, { useContext } from "react";
import { Tooltip, ButtonBase } from "@material-ui/core";
import GlobalContext from "../../../../../../hooks/GlobalContext";
import MeaningIcons from "./MeaningIcons";

export default function(props) {
    const { id, name, iconId } = props;
    const { pagingState } = useContext(GlobalContext);
    const {
        selectedPagingPersonId,
        selectedMeaningButtonId,
        setSelectedMeaningButtonId
    } = pagingState;
    const isSelected = selectedMeaningButtonId === id;

    return (
        <Tooltip title={name}>
            <ButtonBase
                onClick={() => {
                    if (id === selectedMeaningButtonId) {
                        setSelectedMeaningButtonId(null);
                    } else {
                        if (selectedPagingPersonId) {
                            setSelectedMeaningButtonId(id);
                        }
                    }
                }}
                size="small"
                style={{
                    backgroundColor: "white",
                    minWidth: "0",
                    overflow: "hidden",
                    borderRadius: 10,
                    border: isSelected
                        ? "2px solid black"
                        : "2px solid transparent"
                }}
            >
                <MeaningIcons iconId={iconId}></MeaningIcons>
            </ButtonBase>
        </Tooltip>
    );
}
