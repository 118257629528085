import React, { useEffect, useContext, useState } from "react";
import { Router } from "react-router-dom";

import MessagingContext from "./MessagingContext";

import MessagingMainDisplay from "./MessagingMainDisplay";
import useMessaging from "./useMessaging";
import { Card } from "@material-ui/core";
import TopBar from "./TopBar";
import messagingRouterHistory from "./messagingRouterHistory";
import GlobalContext from "../../../../../hooks/GlobalContext";

export default function(props) {
    const messaging = useMessaging();
    const { localStore } = useContext(GlobalContext);

    return (
        <MessagingContext.Provider value={messaging}>
            <Router history={messagingRouterHistory}>
                <Card
                    style={{
                        border: "1px solid black",
                        overflow: "visible"
                    }}
                >
                    <TopBar></TopBar>
                    <MessagingMainDisplay></MessagingMainDisplay>
                </Card>
            </Router>
        </MessagingContext.Provider>
    );
}
