import React from "react";
import { Route } from "react-router-dom";
import LicenseSelect from "./LicenseSelect/LicenseSelect";
import App from "./App/App";

export default function(props) {
    return (
        <div style={{ width: "100%" }}>
            <Route
                exact
                path="/office/licenses"
                component={LicenseSelect}
            ></Route>
            <Route exact path="/office/app" component={App}></Route>
        </div>
    );
}
