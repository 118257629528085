import { createContext } from "react";
import { useOfficeDoc } from "../firebase/docListeners/office";
import { useLicensesDoc } from "../firebase/docListeners/licenses";
import { usePagingDoc } from "../firebase/docListeners/paging";
import useAuth from "./useAuth";
import useMainRouter from "./useMainRouter";
import useMain from "./useMain";
import useLocalStore from "./useLocalStore";
import usePagingState from "./usePagingState";
import useNavigation from "./useNavigation";
import useOfficeList from "./useOfficeList";
import useVolumeControl from "./useVolumeControl";
import { usePatientTrackingDoc } from "../firebase/docListeners/patientTracking";

export default createContext({
    officeId: null,
    setOfficeId: () => {}
});

export function useGlobalContext() {
    const mainRouter = useMainRouter();
    const authCtx = useAuth(mainRouter);
    const officeDoc = useOfficeDoc();
    const licensesDoc = useLicensesDoc();
    const pagingDoc = usePagingDoc();
    const patientTrackingDoc = usePatientTrackingDoc();
    const officeList = useOfficeList();
    const mainCtx = useMain();
    const localStore = useLocalStore();
    const pagingState = usePagingState();
    const navigation = useNavigation({
        mainCtx,
        pagingDoc,
        licensesDoc,
        patientTrackingDoc,
        mainRouter
    });

    const volumeControl = useVolumeControl({ localStore });

    return {
        mainRouter,
        authCtx,
        officeDoc,
        licensesDoc,
        pagingDoc,
        patientTrackingDoc,
        mainCtx,
        localStore,
        pagingState,
        navigation,
        officeList,
        volumeControl
    };
}
