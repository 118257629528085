import React, { useState, useEffect, useContext, useRef } from "react";
import GlobalContext from "../../../../../hooks/GlobalContext";
import { useDocListener } from "./docListener";
import { useQueryListener } from "./queryListener";
import messagingRouterHistory from "./messagingRouterHistory";
import { playSoundEffect } from "../../../../../audio/audio";
import soundEffects from "../../../../../audio/soundEffects";

export default function (props) {
    const { mainCtx, localStore } = useContext(GlobalContext);
    const { officeId } = mainCtx;

    const [selectedUserId, setSelectedUserId] = useState(null);
    const [selectedConversationId, setSelectedConversationId] = useState(null);
    const [initializing, setInitializing] = useState(true);
    const lastAlertTime = useRef(Date.now());

    let users = [];
    let conversations = [];

    const messagingDocListener = useDocListener();
    const conversationQueryListener = useQueryListener();

    if (messagingDocListener.data && messagingDocListener.data.users) {
        users = ListifyUsers(messagingDocListener.data.users);
    }

    if (conversationQueryListener.data) {
        conversations = conversationQueryListener.data;
    }

    //load last user on start
    useEffect(() => {
        if (initializing && users.length > 0) {
            const storedMessagingUserId =
                localStore.data.selectedMessagingUserId;
            if (!storedMessagingUserId) {
                return;
            }
            const userExists = users.some((user) => {
                return user.id === storedMessagingUserId;
            });
            if (userExists) {
                selectUserId(storedMessagingUserId);
            }
            setInitializing(false);
        }
    }, [messagingDocListener.data]);

    useEffect(() => {
        if (selectedUserId) {
            if (!getUserById(selectedUserId)) {
                console.log("user not found");
                messagingRouterHistory.push("/conversationList");
                conversationQueryListener.unsubscribe();
                setSelectedUserId(null);
                setSelectedConversationId(null);
            }
        }
        if (selectedConversationId) {
            if (!getConversationById(selectedConversationId)) {
                console.log("conversation not found");
                messagingRouterHistory.push("/conversationList");
                setSelectedConversationId(null);
            }
        }
        conversations.forEach((conv) => {
            const latestMessage = conv.latestMessage;
            const isNew = latestMessage.time > lastAlertTime.current;
            const isByMe =
                conv.participants[selectedUserId].lastMessage.id ===
                latestMessage.id;

            if (!isByMe && isNew) {
                lastAlertTime.current = latestMessage.time;
                if (localStore.data.messagingSoundEnabled) {
                    playSoundEffect(soundEffects["s16"]);
                }
            }
        });
    }, [users, conversations]);

    function selectUserId(id) {
        if (id !== selectedUserId) {
            conversationQueryListener.unsubscribe();
            setSelectedConversationId(null);
            setSelectedUserId(id);
        }
    }

    function selectConversationId(id) {
        setSelectedConversationId(id);
    }

    function getUserById(id) {
        const matches = users.filter((user) => {
            return user.id === id;
        });
        if (matches.length > 0) {
            return matches[0];
        } else {
            return null;
        }
    }

    function getConversationById(id) {
        const matches = conversations.filter((conversation) => {
            return conversation.id === id;
        });
        if (matches.length > 0) {
            return matches[0];
        } else {
            return null;
        }
    }

    useEffect(() => {
        selectUserId(null);
        if (!officeId) {
            messagingDocListener.unsubscribe();
            return;
        }
        messagingDocListener.subscribe(`/offices/${officeId}/app/messaging/`);
    }, [officeId]);

    useEffect(() => {
        if (!selectedUserId) {
            conversationQueryListener.unsubscribe();
            return;
        }
        conversationQueryListener.subscribe(
            `/offices/${officeId}/app/messaging/conversations`,
            [["where", "userIds", "array-contains", selectedUserId]]
        );
    }, [selectedUserId]);

    return {
        users,
        conversations,
        selectedUserId,
        selectUserId,
        selectedConversationId,
        selectConversationId,
        getUserById,
        getConversationById,
        messagingDocListener,
        conversationQueryListener,
    };
}

function ListifyUsers(usersObj) {
    return Object.keys(usersObj)
        .map((userId) => {
            return usersObj[userId];
        })
        .sort((a, b) => {
            if (!a.name || b.name) {
                return -1;
            }
            return a.name.localeCompare(b.name);
        });
}
