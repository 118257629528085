import React from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";

export default function(props) {
    return (
        <Tooltip title="Portal">
            <IconButton
                size="small"
                style={{ color: "white" }}
                onClick={() => {
                    window.open("http://portal.insyncapp.io");
                }}
            >
                <DashboardIcon></DashboardIcon>
            </IconButton>
        </Tooltip>
    );
}
