import React, { useContext } from "react";
import { ButtonBase, Paper } from "@material-ui/core";
import GlobalContext from "../../../../../../hooks/GlobalContext";

export default function(props) {
    const person = props.person;
    const { pagingState, localStore } = useContext(GlobalContext);
    const { selectedPagingPersonId, setSelectedPagingPersonId } = pagingState;
    const isSelected = selectedPagingPersonId === person.personId;
    const personStyle = (() => {
        let style = {
            minWidth: "0",
            overflow: "hidden",
            borderRadius: 10,
            // border: `1px solid black`,
            color:
                person.numberOfRoomsPagedTo > 0 ||
                localStore.data.personColorsAlwaysVisible
                    ? person.textColor
                    : `black`,
            backgroundColor:
                person.numberOfRoomsPagedTo > 0 ||
                localStore.data.personColorsAlwaysVisible
                    ? person.bgColor
                    : `white`,
            fontWeight: isSelected ? `bolder` : `normal`
        };

        return style;
    })();

    return (
        <ButtonBase
            onClick={() => {
                if (person.personId === selectedPagingPersonId) {
                    setSelectedPagingPersonId(null);
                } else {
                    setSelectedPagingPersonId(person.personId);
                }
            }}
            component={Paper}
            style={personStyle}
        >
            {person.name}
        </ButtonBase>
    );
}
