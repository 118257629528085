import { createContext, useState } from "react";

export default function useNavigation({
    mainCtx,
    pagingDoc,
    licensesDoc,
    patientTrackingDoc,
    mainRouter
}) {
    const toOfficeSelect = () => {
        pagingDoc.unsubscribe();
        licensesDoc.unsubscribe();
        patientTrackingDoc.unsubscribe();
        mainRouter.push("/offices");
    };

    const toLicenseSelect = officeId => {
        pagingDoc.unsubscribe();
        patientTrackingDoc.unsubscribe();
        licensesDoc.subscribe(officeId);
        mainRouter.push("/office/licenses");
    };

    const toMainAppAdmin = officeId => {
        pagingDoc.subscribe(officeId);
        patientTrackingDoc.subscribe(officeId);
        mainRouter.push("/office/app");
    };

    const toMainApp = officeId => {
        pagingDoc.subscribe(officeId);
        patientTrackingDoc.subscribe(officeId);
        mainRouter.push("/office/app");
    };

    const toSignIn = () => {
        mainCtx.setOfficeId(null);
        pagingDoc.unsubscribe();
        licensesDoc.unsubscribe();
        patientTrackingDoc.unsubscribe();
        mainRouter.push("/signin");
    };

    return {
        toOfficeSelect,
        toLicenseSelect,
        toMainApp,
        toMainAppAdmin,
        toSignIn
    };
}
