import React, { useState, useEffect, useContext } from "react";
import PatientTrackingContext from "../PatientTrackingContext";

const cellStyle = {
    borderRight: "1px solid black",
    fontWeight: "bold",
    paddingLeft: "2px",
    paddingRight: "2px",
    overflowX: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    fontSize: "12px",
    textAlign: "center",
    userSelect: "none"
};

export default function(props) {
    const { columnLayout } = useContext(PatientTrackingContext);

    const cellDictionary = {
        Name: (
            <div key={"Name"} style={cellStyle}>
                Name
            </div>
        ),
        Room: (
            <div key={"Room"} style={cellStyle}>
                Room
            </div>
        ),
        Stage: (
            <div key={"Stage"} style={cellStyle}>
                Stage
            </div>
        ),
        ArrivalTime: (
            <div key={"ArrivalTime"} style={cellStyle}>
                ArrivalTime
            </div>
        ),
        AppointmentTime: (
            <div key={"AppointmentTime"} style={cellStyle}>
                AppointmentTime
            </div>
        ),
        TimeElapsed: (
            <div key={"TimeElapsed"} style={cellStyle}>
                TimeElapsed
            </div>
        ),
        TimeRemaining: (
            <div key={"TimeRemaining"} style={cellStyle}>
                TimeRemaining
            </div>
        )
    };

    return (
        <div
            style={{
                display: "grid",
                gridTemplateColumns: `repeat(${columnLayout.length}, minmax(0, 1fr))`,
                textAlign: "center",
                borderBottom: "1px solid black",
                borderRight: "1px solid black"
            }}
        >
            {columnLayout.map(element => {
                return cellDictionary[element];
            })}
        </div>
    );
}
