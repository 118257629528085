import React, { useState } from "react";
import { IconButton, Menu, Tooltip } from "@material-ui/core";

// import VisibilityIcon from "@material-ui/icons/Visibility";

import VolumeIcon from "@material-ui/icons/VolumeUp";
import SoundMenu from "./SoundMenu";

export default function VolumeButton(props) {
    const [anchorEl, setAnchorEl] = useState(null);

    return (
        <React.Fragment>
            <Tooltip title="Volume Settings">
                <IconButton
                    size="small"
                    onClick={e => {
                        setAnchorEl(e.currentTarget);
                    }}
                >
                    <VolumeIcon style={{ color: "white" }} />
                </IconButton>
            </Tooltip>
            <Menu
                disableScrollLock
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => {
                    setAnchorEl(null);
                }}
            >
                <SoundMenu></SoundMenu>
            </Menu>
        </React.Fragment>
    );
}
