import React, { useState, useContext } from "react";
import GlobalContext from "../../../../../../hooks/GlobalContext";
import MeaningButton from "./MeaningButton";
import { Resizable } from "re-resizable";

export default function (props) {
    const { localStore, pagingDoc } = useContext(GlobalContext);
    const { meaningButtons } = pagingDoc;

    return (
        <Resizable
            style={{ display: "flex" }}
            defaultSize={{
                height: `${localStore.data.meaningButtonsHeight}px`,
            }}
            size={{
                height: `${localStore.data.meaningButtonsHeight}px`,
            }}
            onResizeStop={(event, direction, refToElement, delta) => {
                const newHeight = parseInt(refToElement.style.height);
                localStore.update(
                    localStore.storageKeys.meaningButtonsHeight,
                    newHeight
                );
            }}
            enable={{ bottom: true }}
        >
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: `repeat(${localStore.data.meaningButtonsColumns}, minmax(0,1fr))`,
                    gridAutoRows: "auto",
                    overflowY: "auto",
                    overflowX: "hidden",
                    width: "100%",

                    gridGap: "1px",
                    // paddingBottom: "2px",
                    // paddingTop: "2px"
                }}
            >
                {getSortedMeaningButtonIds(meaningButtons).map((mbId) => {
                    return (
                        <MeaningButton
                            key={mbId}
                            id={mbId}
                            name={meaningButtons[mbId].name}
                            iconId={meaningButtons[mbId].iconId}
                        ></MeaningButton>
                    );
                })}
            </div>
        </Resizable>
    );
}

function getSortedMeaningButtonIds(meaningButtons) {
    const sorted = Object.keys(meaningButtons)
        .sort((a, b) => {
            if (meaningButtons[a].name > meaningButtons[b].name) {
                return 1;
            }
            if (meaningButtons[a].name < meaningButtons[b].name) {
                return -1;
            }
            return 0;
        })
        .sort((a, b) => {
            const meaningButtonA = { ...meaningButtons[a] };
            const meaningButtonB = { ...meaningButtons[b] };
            meaningButtonA.listPosition = meaningButtonA.listPosition
                ? meaningButtonA.listPosition
                : 0;
            meaningButtonB.listPosition = meaningButtonB.listPosition
                ? meaningButtonB.listPosition
                : 0;
            return meaningButtonA.listPosition - meaningButtonB.listPosition;
        });

    return sorted;
}
