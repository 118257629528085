import React, { useContext } from "react";
import messagingRouterHistory from "./messagingRouterHistory";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import { IconButton, Badge } from "@material-ui/core";
import MessagingContext from "./MessagingContext";

export default function(props) {
    const messaging = useContext(MessagingContext);
    const hasUnread = (() => {
        const filteredConversations = messaging.conversations.filter(
            conversation => {
                return conversation.id !== messaging.selectedConversationId;
            }
        );

        return filteredConversations.some(conversation => {
            const lastReadTime = parseInt(
                conversation.participants[messaging.selectedUserId].lastMessage
                    .time
            );

            const latestTime = parseInt(conversation.latestMessage.time);

            return lastReadTime < latestTime;
        });
    })();

    return (
        <Badge
            variant="dot"
            badgeContent={hasUnread ? 1 : 0}
            color={"secondary"}
            overlap="circle"
        >
            <IconButton
                size="small"
                onClick={() => {
                    messagingRouterHistory.push("/conversationList");
                }}
            >
                <ArrowLeftIcon style={{ color: "white" }}></ArrowLeftIcon>
            </IconButton>
        </Badge>
    );
}
