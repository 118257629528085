import React, { useContext, useState } from "react";
import {
    Modal,
    Card,
    CardContent,
    Button,
    CircularProgress
} from "@material-ui/core";
import endVisit from "../../httpsCallable/endVisit";
import { VisitContext } from "../../VisitContext";
import GlobalContext from "../../../../../../../hooks/GlobalContext";

export default function(props) {
    const { open, onClose } = props;
    const visit = useContext(VisitContext);
    const { mainCtx } = useContext(GlobalContext);
    const [loading, setLoading] = useState(false);
    const { officeId } = mainCtx;

    return (
        <Modal
            open={open}
            onClose={onClose}
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <Card>
                <CardContent>
                    <Button
                        variant="contained"
                        onClick={() => {
                            setLoading(true);
                            endVisit({
                                officeId: officeId,
                                visitId: visit.id
                            })
                                .then(result => {
                                    setLoading(false);
                                    console.log(result);
                                    onClose();
                                })
                                .catch(err => {
                                    setLoading(false);
                                    console.error(err);
                                });
                        }}
                    >
                        End Visit
                    </Button>
                    {loading && <CircularProgress></CircularProgress>}
                </CardContent>
            </Card>
        </Modal>
    );
}
