import { createContext, useState, useRef } from "react";
import firebase from "../firebase";

export const pagingDocContext = createContext({
    persons: {},
    rooms: {},
    events: {},
    subscribe: () => {},
    unsubscribe: () => {}
});

export const usePagingDoc = () => {
    const [persons, setPersons] = useState({});
    const [rooms, setRooms] = useState({});
    const [events, setEvents] = useState({});
    const [meaningButtons, setMeaningButtons] = useState({});
    const [listenError, setListenError] = useState(null);
    const [loading, setLoading] = useState(false);
    const unsubscribeRef = useRef(null);
    const latestEventTime = useRef(new Date().valueOf());

    const snapshotHandler = doc => {
        const data = doc.data();
        const persons = data.persons;
        const rooms = data.rooms;
        const events = data.events;
        const meaningButtons = data.meaningButtons;

        setEvents(events);

        if (!meaningButtons) {
            setMeaningButtons({});
        } else {
            setMeaningButtons(meaningButtons);
        }

        if (!persons || !rooms) {
            setPersons({});
            setRooms({});
        }

        //get number of rooms paged to for each person
        Object.keys(persons).forEach(personId => {
            const numberOfRoomsPagedTo = Object.keys(rooms).reduce(
                (accumulator, roomId) => {
                    if (rooms[roomId].persons[personId]) {
                        accumulator++;
                    }
                    return accumulator;
                },
                0
            );
            persons[personId] = { ...persons[personId], numberOfRoomsPagedTo };
        });

        //give rooms as much information as possible
        Object.keys(rooms).forEach(roomId => {
            const room = rooms[roomId];
            Object.keys(room.persons).forEach(personId => {
                const entryKeys = Object.keys(room.persons[personId]).sort();
                const firstEntryKey = entryKeys[0];

                const latestEntryKey = Object.keys(
                    room.persons[personId]
                ).sort()[entryKeys.length - 1];

                const pagedSince = firstEntryKey;

                const meaningButtonId =
                    room.persons[personId][latestEntryKey].meaningButtonId;

                room.persons[personId] = {
                    ...persons[personId],
                    pagedSince,
                    meaningButtonId
                };
                return room.persons[personId];
            });
        });

        setPersons(persons);
        setRooms(rooms);
        setLoading(false);
    };

    const subscribe = officeId => {
        setLoading(true);
        latestEventTime.current = new Date().valueOf();
        setListenError(null);
        unsubscribe();
        unsubscribeRef.current = firebase
            .firestore()
            .doc(`/offices/${officeId}/app/paging`)
            .onSnapshot(snapshotHandler, err => {
                setListenError(err);
            });
    };

    const unsubscribe = () => {
        setPersons({});
        setRooms({});
        unsubscribeRef.current && unsubscribeRef.current();
    };

    return {
        persons,
        rooms,
        meaningButtons,
        subscribe,
        unsubscribe,
        listenError,
        events,
        loading
    };
};
