import React, { useEffect, useState, useRef, useContext } from "react";
import pagePersonToRoom from "../../../../../../../firebase/httpsCallable/pagePersonToRoom";
import RoomContext from "./RoomContext";
import PersonsContainer from "./PersonsContainer";
import { ButtonBase, Tooltip, Paper } from "@material-ui/core";
import { useTimer } from "../../TimerContext";
import GlobalContext from "../../../../../../../hooks/GlobalContext";

export default function(props) {
    const { mainCtx, pagingDoc, pagingState } = useContext(GlobalContext);
    const { officeId } = mainCtx;
    const { persons: allPersons } = pagingDoc;
    const {
        selectedPagingPersonId,
        setSelectedPagingPersonId,
        setSelectedMeaningButtonId,
        selectedMeaningButtonId
    } = pagingState;
    const { pause, unpause, onTick } = useTimer();
    const room = props.room;
    const persons = room.persons;
    const displayedPersonIndexRef = useRef(0);
    const [displayedPersonIndex, setDisplayedPersonIndex] = useState(0);

    const setIndex = index => {
        displayedPersonIndexRef.current = index;
        setDisplayedPersonIndex(index);
    };
    useEffect(() => {
        onTick(() => {
            setIndex(displayedPersonIndexRef.current + 1);
        });

        return () => {
            onTick(() => {});
        };
    }, []);

    const displayedPersonId = Object.keys(persons)[
        displayedPersonIndexRef.current % Object.keys(persons).length
    ];

    return (
        <RoomContext.Provider
            value={{
                room,
                persons,
                displayedPersonId,
                setIndex
            }}
        >
            <Tooltip
                title={
                    selectedPagingPersonId && room && room.name
                        ? `${allPersons[selectedPagingPersonId].name} to ${room.name}`
                        : ""
                }
                enterTouchDelay={200}
            >
                <Paper
                    style={{
                        backgroundColor: "black",
                        color: "white",
                        minWidth: "0",
                        overflow: "hidden",
                        borderRadius: 10,
                        display: "grid",
                        gridTemplateRows: `minmax(0px, 1fr) minmax(0px,2fr)`,
                        textAlign: "center"
                    }}
                    onMouseEnter={() => {
                        pause();
                    }}
                    onMouseLeave={() => {
                        unpause();
                    }}
                    onClick={e => {
                        if (selectedPagingPersonId) {
                            setSelectedMeaningButtonId(null);
                            pagePersonToRoom({
                                officeId,
                                personId: selectedPagingPersonId,
                                meaningButtonId: selectedMeaningButtonId,
                                roomId: room.roomId
                            });
                            setSelectedPagingPersonId(null);
                        }
                    }}
                >
                    <ButtonBase>
                        <div className="roomname">{room.name}</div>
                    </ButtonBase>
                    <PersonsContainer />
                </Paper>
            </Tooltip>
        </RoomContext.Provider>
    );
}
