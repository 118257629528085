import React, { useContext, useState } from "react";
import { Chip } from "@material-ui/core";
import { VisitContext } from "../../VisitContext";
import moment from "moment";
import TimesModal from "../Modals/TimesModal";

export default function(props) {
    const { style } = props;
    const visit = useContext(VisitContext);
    const [modalOpen, setModalOpen] = useState(false);
    const timeString = moment(visit.arrivalTime).format("LT");
    return (
        <React.Fragment>
            <div
                style={style}
                onClick={() => {
                    setModalOpen(true);
                }}
            >
                {/* <Chip
                    size="small"
                    style={{
                        padding: 0,
                        margin: 0,
                        maxWidth: "100%",
                        cursor: "pointer"
                    }}
                    label={timeString}
                ></Chip> */}
                {timeString}
            </div>
            <TimesModal
                open={modalOpen}
                onClose={() => {
                    setModalOpen(false);
                }}
            ></TimesModal>
        </React.Fragment>
    );
}
