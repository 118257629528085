const AudioContext = window.AudioContext || window.webkitAudioContext;
const audioCtx = new AudioContext();
const masterGain = audioCtx.createGain();
const masterCompressor = audioCtx.createDynamicsCompressor();
masterCompressor.threshold.value = -20;
masterCompressor.knee.value = 20;
masterCompressor.release.value = 1;
masterGain.connect(masterCompressor);
masterCompressor.connect(audioCtx.destination);

export const setMasterGain = val => {
    const mg = computedGainFromInt(val / 100);
    masterGain.gain.value = mg;
};

export const getMasterGain = () => {
    const localItem = localStorage.getItem("insyncvolume");
    const mg = (localItem && parseInt(localItem)) || 0;
    return mg;
};

const defaultSynthTemplate = {
    oscillators: [
        { frequencyRatio: 1, volume: 0.8 },
        { frequencyRatio: 2, volume: 0.4 }
    ],

    volumeEnvelope: {
        attack: 0.01,
        decay: 0,
        sustain: 1,
        release: 0.05
    }
};

const rootFreq = 440;
const defaultNoteSequence = [
    { duration: 1, rootFrequency: rootFreq, frequencyRatio: 1 },
    { duration: 1, rootFrequency: rootFreq, frequencyRatio: 2 },
    { duration: 1, rootFrequency: rootFreq, frequencyRatio: 3 }
];

export function playNote({
    startTime,
    note,
    synthTemplate,
    tempoUnit,
    masterGain
}) {
    for (let i = 0; i < synthTemplate.oscillators.length; i++) {
        const oscTemplate = synthTemplate.oscillators[i];
        const osc = audioCtx.createOscillator();

        osc.type = "sine";
        osc.frequency.setValueAtTime(
            oscTemplate.frequencyRatio *
                note.rootFrequency *
                note.frequencyRatio,
            startTime
        );

        const amp = audioCtx.createGain();
        amp.gain.value = 0;
        amp.gain.setValueAtTime(
            0,
            startTime
        );
        amp.gain.linearRampToValueAtTime(
            oscTemplate.volume,
            startTime + synthTemplate.volumeEnvelope.attack
        );
        amp.gain.linearRampToValueAtTime(
            0.001,
            startTime +
                note.duration * tempoUnit +
                synthTemplate.volumeEnvelope.release
        );

        osc.connect(amp).connect(masterGain);
        osc.start(startTime);
        osc.stop(
            startTime +
                note.duration * tempoUnit +
                synthTemplate.volumeEnvelope.release 
        );
    }
    return startTime + note.duration * tempoUnit;
}

export function playSequence(noteSequence, synthTemplate, tempoUnit) {
    if (!synthTemplate) {
        synthTemplate = defaultSynthTemplate;
    }
    if (!noteSequence) {
        noteSequence = defaultNoteSequence;
    }
    let noteStartingTime = audioCtx.currentTime;
    for (const note of noteSequence) {
        noteStartingTime = playNote({
            startTime: noteStartingTime,
            note,
            synthTemplate: synthTemplate,
            tempoUnit: tempoUnit ? tempoUnit : 0.1,
            masterGain
        });
    }
}

export function playSoundEffect(soundEffect) {
    // const local = JSON.parse(localStorage.getItem("insync"));

    // masterGain.gain.value = local && local.volume ? local.volume : 0;
    // console.log(soundEffect);
    const parts = soundEffect.parts;
    parts.map(part => {
        playSequence(
            part.noteSequence,
            part.synthTemplate,
            soundEffect.tempoUnit
        );
        return null;
    });
}

export function volumeTest() {
    playNote({
        startTime: audioCtx.currentTime,
        note: { duration: 1, rootFrequency: 440, frequencyRatio: 1 },
        synthTemplate: defaultSynthTemplate,
        tempoUnit: 0,
        masterGain
    });
}

function computedGainFromInt(val) {
    return Math.pow(10, val / 20) - 1;
}

window.playSound = playSoundEffect;

window.playSequence = playSequence;

window.volumeTest = volumeTest;
