import React, { useContext } from "react";
import { Route } from "react-router-dom";
import ConversationListNav from "./ConversationListNav";
import ConversationNav from "./ConversationNav";
import CreateConversationNav from "./CreateConversationNav";
import MessagingContext from "../MessagingContext";

export default function(props) {
    const messaging = useContext(MessagingContext);
    return (
        <div style={{ textAlign: "center" }}>
            {messaging.selectedUserId && (
                <React.Fragment>
                    <Route
                        exact
                        path="/conversationList"
                        component={ConversationListNav}
                    ></Route>
                    <Route
                        exact
                        path="/conversation"
                        component={ConversationNav}
                    ></Route>
                    <Route
                        exact
                        path="/createConversation"
                        component={CreateConversationNav}
                    ></Route>
                </React.Fragment>
            )}
        </div>
    );
}
