import React, { useState, useContext } from "react";
import {
    IconButton,
    Modal,
    Card,
    CardContent,
    TextField,
    Button,
    CardHeader,
    CircularProgress
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import createVisit from "./httpsCallable/createVisit";
import GlobalContext from "../../../../../hooks/GlobalContext";
import PatientTrackingContext from "./PatientTrackingContext";

export default function(props) {
    const [modalOpen, setModalOpen] = useState(false);
    const [patientName, setPatientName] = useState("");
    const [arrivalTime, setArrivalTime] = useState(Date.now());
    const [appointmentTime, setAppointmentTime] = useState(Date.now());
    //count from
    const { patientTrackingDoc } = useContext(PatientTrackingContext);
    const { defaults, visitSettings } = patientTrackingDoc;

    const countTime = (() => {
        if (visitSettings && visitSettings.countFrom) {
            return visitSettings.countFrom === "appointmentTimme"
                ? appointmentTime
                : arrivalTime;
        } else {
            return appointmentTime;
        }
    })();

    const [appointmentLength, setAppointmentLength] = useState(
        defaults && defaults.appointmentLength ? defaults.appointmentLength : 60
    );
    const [loading, setLoading] = useState(false);
    const { mainCtx } = useContext(GlobalContext);
    const { officeId } = mainCtx;

    function resetValues() {
        setArrivalTime(Date.now());
        setAppointmentTime(Date.now());
        setPatientName("");
        setAppointmentLength(
            defaults && defaults.appointmentLength
                ? defaults.appointmentLength
                : 60
        );
    }

    async function submitNewVisit() {
        setLoading(true);
        createVisit({
            officeId: officeId,
            patientName,
            arrivalTime,
            appointmentTime,
            dueOutTime: appointmentTime + appointmentLength * 60000
        })
            .then(result => {
                console.log(result);
                setModalOpen(false);
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                console.error(err);
            });
    }

    return (
        <React.Fragment>
            <IconButton
                size="small"
                onClick={() => {
                    resetValues();
                    setModalOpen(true);
                }}
            >
                <AddIcon></AddIcon>
            </IconButton>
            <Modal
                style={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex"
                }}
                open={modalOpen}
                onClose={() => {
                    setModalOpen(false);
                }}
            >
                <Card>
                    <CardHeader title="New Visit"></CardHeader>
                    <CardContent>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <div>
                                <TextField
                                    label="Patient Name"
                                    value={patientName}
                                    onChange={e => {
                                        setPatientName(e.target.value);
                                    }}
                                ></TextField>
                            </div>
                            <div>
                                <KeyboardTimePicker
                                    ampm={true}
                                    fullWidth
                                    margin="normal"
                                    id="arrival-time picker"
                                    label="Arrival Time"
                                    value={arrivalTime}
                                    onChange={e => {
                                        const time = e
                                            ? e.valueOf()
                                            : Date.now();
                                        setArrivalTime(time);
                                    }}
                                />
                            </div>
                            <div>
                                <KeyboardTimePicker
                                    ampm={true}
                                    fullWidth
                                    margin="normal"
                                    id="appointment-time picker"
                                    label="Appointment Time"
                                    value={appointmentTime}
                                    onChange={e => {
                                        const time = e
                                            ? e.valueOf()
                                            : Date.now();
                                        setAppointmentTime(time);
                                    }}
                                />
                            </div>
                            <div>
                                <TextField
                                    label="Appointment Length (Minutes)"
                                    type="number"
                                    inputProps={{ min: 0 }}
                                    value={appointmentLength}
                                    onChange={e => {
                                        setAppointmentLength(e.target.value);
                                    }}
                                ></TextField>
                            </div>
                            <div>
                                <KeyboardTimePicker
                                    ampm={true}
                                    fullWidth
                                    margin="normal"
                                    id="due-out-time picker"
                                    label="Due Out Time"
                                    value={
                                        countTime + appointmentLength * 60000
                                    }
                                    onChange={e => {
                                        const time = e
                                            ? e.valueOf()
                                            : Date.now();
                                        setAppointmentLength(
                                            Math.floor(
                                                (time - countTime) / 60000
                                            )
                                        );
                                    }}
                                />
                            </div>
                            <div>
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        submitNewVisit();
                                    }}
                                >
                                    submit
                                </Button>
                                {loading && (
                                    <CircularProgress></CircularProgress>
                                )}
                            </div>
                        </MuiPickersUtilsProvider>
                    </CardContent>
                </Card>
            </Modal>
        </React.Fragment>
    );
}
