import firebase from "../../../../../../firebase/firebase";

export default function({ officeId, visitId, roomId }) {
    // console.log({ officeId, visitId, roomId });
    return firebase.functions().httpsCallable("allFunctions")({
        funcRole: "patientTrackingUser",
        funcName: "moveToRoom",
        data: { officeId, visitId, roomId }
    });
}
