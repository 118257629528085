import React, { useContext } from "react";
import Tabs from "./Tabs";
import RoomContext from "./RoomContext";
import DisplayedPerson from "./DisplayedPerson";
import { Paper } from "@material-ui/core";

export default function PersonsContainer(props) {
    const { persons, displayedPersonId } = useContext(RoomContext);
    const numberOfPersons = Object.keys(persons).length;

    return (
        <Paper
            className="personsContainer"
            style={{
                cursor: "pointer",
                display: "grid",
                gridTemplateRows: "minmax(0px, 1fr) minmax(0px,1fr)"
                // gridTemplateRows: numberOfPersons > 1 ? "1fr 2fr" : "1fr"
            }}
        >
            <Tabs></Tabs>
            {/* {numberOfPersons > 1 && <Tabs />} */}
            {displayedPersonId && <DisplayedPerson />}
        </Paper>
    );
}
