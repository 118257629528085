import firebase from "../../../../../../firebase/firebase";

export default function({
    officeId,
    patientName,
    arrivalTime,
    appointmentTime,
    dueOutTime
}) {
    return firebase.functions().httpsCallable("allFunctions")({
        funcRole: "patientTrackingUser",
        funcName: "createVisit",
        data: {
            officeId,
            patientName,
            arrivalTime,
            appointmentTime,
            dueOutTime
        }
    });
}
