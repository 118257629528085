import React, { useState, useContext } from "react";
import { Chip } from "@material-ui/core";
import EndVisitModal from "../Modals/EndVisitModal";
import { VisitContext } from "../../VisitContext";
export default function(props) {
    const { style } = props;
    const visit = useContext(VisitContext);
    const [modalOpen, setModalOpen] = useState(false);
    const minutesRemaining = Math.floor(
        (parseInt(visit.dueOutTime) - Date.now()) / 60000
    );

    // const { patientTrackingDoc } = useContext(PatientTrackingContext);

    // const countFromTime = (() => {
    //     return patientTrackingDoc.visitSettings &&
    //         patientTrackingDoc.visitSettings.countFrom &&
    //         patientTrackingDoc.visitSettings.countFrom === "appointmentTime"
    //         ? visit.appointmentTime
    //         : visit.arrivalTime;
    // })();

    const timeStyle = {
        color: minutesRemaining > 0 ? "green" : "red"
    };
    return (
        <React.Fragment>
            <div
                style={{ ...style, cursor: "pointer" }}
                onClick={() => {
                    setModalOpen(true);
                }}
            >
     
                {minutesRemaining}
            </div>
            <EndVisitModal
                visitId={visit.id}
                open={modalOpen}
                onClose={() => {
                    setModalOpen(false);
                }}
            ></EndVisitModal>
        </React.Fragment>
    );
}
