import React from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";

export default function(props) {
    return (
        <Tooltip title="Support" >
            <IconButton
                size="small"
                style={{ color: "white" }}
                onClick={() => {
                    window.open("http://support.insyncapp.io");
                }}
            >
                <ContactSupportIcon></ContactSupportIcon>
            </IconButton>
        </Tooltip>
    );
}
