import React, { useContext, useState } from "react";
import {
    Modal,
    Card,
    CardContent,
    Button,
    Menu,
    MenuItem,
    CircularProgress
} from "@material-ui/core";
import PatientTrackingContext from "../../PatientTrackingContext";
import moveToroom from "../../httpsCallable/moveToRoom";
import { VisitContext } from "../../VisitContext";
import GlobalContext from "../../../../../../../hooks/GlobalContext";

export default function(props) {
    const { onClose } = props;
    const visit = useContext(VisitContext);
    const { mainCtx } = useContext(GlobalContext);
    const { officeId } = mainCtx;
    const [loading, setLoading] = useState(false);
    const { rooms } = useContext(PatientTrackingContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const latestRoom = visit.roomHistory[visit.roomHistory.length - 1];
    const roomId = latestRoom.roomId;
    const currentroom =
        rooms && rooms[roomId]
            ? rooms[roomId]
            : {
                  id: "??",
                  name: "??"
              };

    const orderedrooms = Object.keys(rooms)
        .sort((idA, idB) => {
            const nameA =
                rooms[idA] && rooms[idA].name ? rooms[idA].name : "zzz";
            const nameB =
                rooms[idB] && rooms[idB].name ? rooms[idB].name : "zzz";

            return nameA.localeCompare(nameB);
        })
        .map(id => {
            return rooms[id];
        });
    return (
        <React.Fragment>
            <Button
                variant="contained"
                onClick={e => {
                    setAnchorEl(e.target);
                }}
            >
                {currentroom.name}
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => {
                    setAnchorEl(null);
                }}
            >
                {orderedrooms.map((room, index) => {
                    return (
                        <MenuItem
                            key={room.id + index}
                            onClick={() => {
                                setLoading(true);
                                moveToroom({
                                    officeId: officeId,
                                    visitId: visit.id,
                                    roomId: room.id
                                })
                                    .then(() => {
                                        setAnchorEl(null);
                                        setLoading(false);
                                        onClose();
                                    })
                                    .catch(err => {
                                        setLoading(false);
                                        console.error(err);
                                    });
                            }}
                        >
                            {room.name}
                        </MenuItem>
                    );
                })}
            </Menu>
            {loading && <CircularProgress></CircularProgress>}
        </React.Fragment>
    );
}
