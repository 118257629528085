import React, { useContext, useState } from "react";
import PatientTrackingContext from "../../PatientTrackingContext";
import { Chip } from "@material-ui/core";
import RoomModal from "../Modals/RoomModal";
import RoomStageModal from "../Modals/RoomStageModal";

export default function(props) {
    const { roomId, style } = props;
    const { rooms } = useContext(PatientTrackingContext);
    const [modalOpen, setModalOpen] = useState(false);
    const existingRoom = rooms[roomId];
    const roomName = existingRoom ? existingRoom.name : "??";

    return (
        <React.Fragment>
            <div
                style={{ ...style, cursor: "pointer" }}
                onClick={() => {
                    setModalOpen(true);
                }}
            >
                {/* <Chip
                    size="small"
                    style={{
                        padding: 0,
                        margin: 0,
                        maxWidth: "100%",
                        cursor: "pointer"
                    }}
                    label={roomName.toString()}
                ></Chip> */}
                {roomName.toString()}
            </div>

            <RoomStageModal
                roomId={roomId}
                open={modalOpen}
                onClose={() => {
                    setModalOpen(false);
                }}
            ></RoomStageModal>
        </React.Fragment>
    );
}
