import React from "react";
import MessageComposer from "./MessageComposer";
import MessageWindow from "./MessageWindow";
import useMessageRepository from "./useMessageRepository";

export default function(props) {
    const messageRepository = useMessageRepository();

    return (
        <React.Fragment>
            <div style={{ display: "grid", minHeight: 0, gridTemplateRows: "4fr 1fr" }}>
                <MessageWindow
                    loadMore={messageRepository.getMessages}
                    messages={messageRepository.messages}
                ></MessageWindow>
                <MessageComposer></MessageComposer>
            </div>
        </React.Fragment>
    );
}
