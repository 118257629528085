import React, { useContext } from "react";
import MessagingContext from "./MessagingContext";
import { format } from "date-fns";
import { Card, IconButton, Tooltip } from "@material-ui/core";
import markAsRead from "../../../../../firebase/httpsCallable/markAsRead";
import GlobalContext from "../../../../../hooks/GlobalContext";
import CheckIcon from "@material-ui/icons/Check";

export default function(props) {
    const { message: myMessage } = props;
    const messageState = useMessageState(myMessage);
    const {
        senderName,
        text,
        time,
        date,
        isUnread,
        isFromMe,
        isLatestMessage,
        tags,
        markAsReadHandler
    } = messageState;

    const fromStyle = (() => {
        if (isFromMe) {
            return {
                float: "right",
                backgroundColor: "#5b5bff",
                color: "white"
            };
        } else {
            return {
                float: "left",
                backgroundColor: "#f1f1f1",
                color: "black"
            };
        }
    })();

    const readStyle = (() => {
        return { border: isUnread ? "1px solid red" : "1px solid transparent" };
    })();

    const tagsStyle = (() => {
        if (!tags || tags.length < 1) {
            return {};
        }

        if (tags.includes("addParticipant")) {
            return { backgroundColor: "#5bff64", color: "black" };
        }
        if (tags.includes("removeParticipant")) {
            return { backgroundColor: "#ff5b5b", color: "black" };
        }
        return {};
    })();

    return (
        <div>
            <Card
                style={{
                    position: "relative",
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",
                    padding: "2px",
                    margin: "2px",
                    ...fromStyle,
                    ...readStyle,
                    ...tagsStyle
                }}
            >
                <div
                    style={{
                        gridRow: "1/2",
                        gridColumn: "1/2",
                        fontSize: "15px",
                        fontWeight: "bolder"
                    }}
                >
                    {senderName}
                </div>
                <div
                    style={{
                        gridRow: "1/2",
                        gridColumn: "3/4",
                        fontSize: "10px",
                        textAlign: "right"
                    }}
                >
                    <div>{time}</div>
                    <div>{date}</div>
                </div>

                <div
                    style={{
                        gridRow: "2/3",
                        gridColumn: "1/4",
                        fontSize: "12px"
                    }}
                >
                    {text}
                </div>
                <div
                    style={{
                        gridRow: "3/4",
                        fontSize: "12px"
                    }}
                >
                    {isLatestMessage && isUnread && (
                        <Tooltip title="mark as read">
                            <IconButton
                                size="small"
                                onClick={markAsReadHandler}
                            >
                                <CheckIcon></CheckIcon>
                            </IconButton>
                        </Tooltip>
                    )}
                </div>
            </Card>
            <div style={{ clear: "both" }}></div>
        </div>
    );
}

function useMessageState(message) {
    const messaging = useContext(MessagingContext);
    const { mainCtx } = useContext(GlobalContext);
    const conversation = messaging.getConversationById(
        messaging.selectedConversationId
    );

    let senderName = "";
    let text = "";
    let time = "";
    let date = "";
    let isUnread = "";
    let isFromMe = "";
    let isLatestMessage = "";
    let tags = [];
    let markAsReadHandler = () => {};

    if (!conversation) {
        return {
            senderName,
            text,
            time,
            date,
            isUnread,
            isFromMe,
            isLatestMessage,
            tags,
            markAsReadHandler
        };
    }

    const user = messaging.getUserById(message.senderId);

    senderName = user && user.name ? user.name : message.senderId;
    text = message.message;
    isFromMe = messaging.selectedUserId === message.senderId;

    try {
        time = format(new Date(message.time), "hh:mma");
        date = format(new Date(message.time), "MM/dd/yy");
    } catch (err) {
        console.log(time);
        console.log(err);
    }

    const lastReadTime = (() => {
        return (
            (conversation.participants &&
                conversation.participants[messaging.selectedUserId] &&
                conversation.participants[messaging.selectedUserId]
                    .lastMessage &&
                conversation.participants[messaging.selectedUserId].lastMessage
                    .time) ||
            0
        );
    })();

    isUnread = (() => {
        if (parseInt(message.time) > parseInt(lastReadTime)) {
            return true;
        }
        return false;
    })();

    isLatestMessage = message.id === conversation.latestMessage.id;

    markAsReadHandler = () => {
        const req = {
            officeId: mainCtx.officeId,
            conversationId: messaging.selectedConversationId,
            messageId: message.id,
            userId: messaging.selectedUserId
        };
        markAsRead(req);
    };

    tags = message.tags;

    return {
        senderName,
        text,
        time,
        date,
        isUnread,
        isFromMe,
        isLatestMessage,
        tags,
        markAsReadHandler
    };
}
