import React from "react";
import { AppBar, Toolbar, Grid } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Nav from "./Nav/Nav";
import messagingRouterHistory from "./messagingRouterHistory";
import HomeButton from "./HomeButton";
import UserSelector from "./UserSelector";

export default withRouter(function(props) {
    return (
        <AppBar
            position="relative"
            style={{ padding: 0, minHeight: 0, overflow: "visible" }}
        >
            <Toolbar
                disableGutters
                variant="dense"
                style={{
                    border: "1px solid black",
                    padding: 0,
                    overflow: "visible",
                    minHeight: "26px",
                    maxHeight: "26px"
                }}
            >
                <Grid
                    container
                    style={{ alignContent: "center", alignItems: "center" }}
                    //  alignContent="center" alignItems="stretch"
                >
                    <Grid item xs={2}>
                        {messagingRouterHistory.location.pathname !==
                            "/conversationList" && <HomeButton></HomeButton>}
                    </Grid>
                    <Grid
                        item
                        xs={8}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <Nav></Nav>
                    </Grid>
                    <Grid
                        item
                        xs={2}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <UserSelector></UserSelector>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
});
