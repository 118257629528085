import React, { useState, useContext } from "react";
import createMessage from "../../../../../firebase/httpsCallable/createMessage";
import GlobalContext from "../../../../../hooks/GlobalContext";
import MessagingContext from "./MessagingContext";
import { TextField, Button, ButtonBase } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";

export default function(props) {
    const [message, setMessage] = useState("");
    const { mainCtx } = useContext(GlobalContext);
    const { officeId } = mainCtx;
    const { selectedConversationId, selectedUserId } = useContext(
        MessagingContext
    );
    const [sending, setSending] = useState(false);

    const sendMessage = () => {
        if (message.length < 1) {
            return;
        }
        setSending(true);

        createMessage({
            officeId: officeId,
            senderId: selectedUserId,
            conversationId: selectedConversationId,
            message: message
        }).then(result => {
            setMessage("");
            setSending(false);
        });
    };
    return (
        <div style={{ display: "grid", gridTemplateColumns: "repeat(10,1fr)", alignItems: "bottom" }}>
            <TextField
                style={{ width: "100%", gridColumn: "1/9" }}
                type="text"
                value={message}
                onKeyPress={e => {
                    if (e.key === "Enter") {
                        sendMessage();
                    }
                }}
                onChange={e => {
                    setMessage(e.target.value);
                }}
            ></TextField>

            <ButtonBase
                disabled={message.length < 1}
                size="small"
                variant="contained"
                style={{ gridColumn: "9/11" }}
                onClick={sendMessage}
            >
                <SendIcon></SendIcon>
            </ButtonBase>
            {/* <div style={{ gridColumn: "1/11" }}>{sending && `SENDING...`}</div> */}
        </div>
    );
}
