import React, { useContext } from "react";
import MessagingContext from "./MessagingContext";
import messagingRouterHistory from "./messagingRouterHistory";
import { Badge, Card } from "@material-ui/core";
import ParticipantsDisplay from "./ParticipantsDisplay";
import { format } from "date-fns";

export default function(props) {
    const messaging = useContext(MessagingContext);
    const { conversation } = props;

    const latesMessageTime =
        conversation &&
        conversation.latestMessage &&
        conversation.latestMessage.time;

    const hasUnread = (() => {
        const lastReadTime =
            conversation &&
            conversation.participants &&
            conversation.participants[messaging.selectedUserId] &&
            conversation.participants[messaging.selectedUserId] &&
            conversation.participants[messaging.selectedUserId].lastMessage
                .time;

        if (lastReadTime < latesMessageTime) {
            return true;
        }
        return false;
    })();

    return (
        <Card
            key={conversation.id}
            style={{
                border: "1px solid black",
                cursor: "pointer",
                padding: "5px"
            }}
            onClick={() => {
                messaging.selectConversationId(conversation.id);
                if (conversation.id) {
                    messagingRouterHistory.push("/conversation");
                }
            }}
        >
            <div>
                <Badge
                    badgeContent={hasUnread ? 1 : 0}
                    variant="dot"
                    anchorOrigin={{ vertical: "top", horizontal: "left" }}
                    color="secondary"
                >
                    {conversation.name ? (
                        `(${conversation.userIds.length})${conversation.name}`
                    ) : (
                        <ParticipantsDisplay
                            participantIds={conversation.userIds.filter(
                                userId => {
                                    return userId !== messaging.selectedUserId;
                                }
                            )}
                        ></ParticipantsDisplay>
                    )}
                </Badge>
            </div>
            <div style={{ color: "grey", fontSize: "10px" }}>
                {format(new Date(latesMessageTime), "hh:mma")}
            </div>
            {/* <div style={{ color: "grey", fontSize: "10px", display: "none" }}>
                id: {conversation.id}
            </div> */}
        </Card>
    );
}
