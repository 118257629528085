import React, { useState, useEffect, useContext } from "react";
import Name from "./Cells/Name";
import Room from "./Cells/Room";
import Stage from "./Cells/Stage";
import ArrivalTime from "./Cells/ArrivalTime";
import AppointmentTime from "./Cells/AppointmentTime";
import TimeElapsed from "./Cells/TimeElapsed";
import TimeRemaining from "./Cells/TimeRemaining";
import PatientTrackingContext from "../PatientTrackingContext";
import { VisitContext } from "../VisitContext";
import TickerContext from "../TickerContext";

const cellStyle = {
    borderRight: "1px solid black",
    fontWeight: "bold",
    paddingLeft: "2px",
    paddingRight: "2px",
    overflowX: "hidden",
    whiteSpace: "nowrap",
    cursor: "pointer",
    textOverflow: "ellipsis",
    fontSize: "12px",
    textAlign: "center",
    userSelect: "none",
};

export default function (props) {
    const { stages, columnLayout } = useContext(PatientTrackingContext);
    const ticks = useContext(TickerContext);
    const visit = useContext(VisitContext);
    const [latestRoom, setLatestRoom] = useState({});
    const [latestStage, setLatestStage] = useState({});

    const minutesRemaining = Math.floor(
        (parseInt(visit.dueOutTime) - Date.now()) / 60000
    );

    const cellDictionary = {
        Name: <Name key={"Name"} style={cellStyle}></Name>,
        Room: (
            <Room
                key={"Room"}
                style={cellStyle}
                roomId={latestRoom.roomId}
            ></Room>
        ),
        Stage: (
            <Stage
                key={"Stage"}
                style={cellStyle}
                stageId={latestStage.stageId}
            ></Stage>
        ),
        ArrivalTime: (
            <ArrivalTime key={"ArrivalTime"} style={cellStyle}></ArrivalTime>
        ),
        AppointmentTime: (
            <AppointmentTime
                key={"AppointmentTime"}
                style={cellStyle}
            ></AppointmentTime>
        ),
        TimeElapsed: (
            <TimeElapsed key={"TimeElapsed"} style={cellStyle}></TimeElapsed>
        ),
        TimeRemaining: (
            <TimeRemaining
                key={"TimeRemaining"}
                style={cellStyle}
            ></TimeRemaining>
        ),
    };

    useEffect(() => {
        setLatestRoom(visit.roomHistory[visit.roomHistory.length - 1]);
        setLatestStage(visit.stageHistory[visit.stageHistory.length - 1]);
    }, [visit.stageHistory, visit.roomHistory]);

    const stageBackgroundColor = (() => {
        const stageColor = latestStage &&
        latestStage.stageId &&
        stages &&
        stages[latestStage.stageId] &&
        stages[latestStage.stageId].backgroundColor

        const defaultColor = "#808080"

        if (minutesRemaining < 1 && ticks % 2 == 0) {
            return invertColor(stageColor || defaultColor)
        }

        return stageColor || defaultColor
    })();

    const stageTextColor = (() => {
        const stageColor = latestStage &&
        latestStage.stageId &&
        stages &&
        stages[latestStage.stageId] &&
        stages[latestStage.stageId].textColor

        const defaultColor = "#FFFFFF"

        if (minutesRemaining < 1 && ticks % 2 == 0) {
            return invertColor(stageColor || defaultColor)
        }

        return stageColor || defaultColor
    })();

    return (
        <div
            style={{
                display: "grid",
                gridTemplateColumns: `repeat(${columnLayout.length}, minmax(0, 1fr))`,
                backgroundColor: stageBackgroundColor,
                color: stageTextColor,
                borderBottom: "1px solid black",
            }}
        >
            {columnLayout.map((element) => {
                return cellDictionary[element];
            })}
        </div>
    );
}


function invertColor(hex) {
    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
        throw new Error('Invalid HEX color.');
    }
    // invert color components
    var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
        g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
        b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
    // pad each with zeros and return
    return '#' + padZero(r) + padZero(g) + padZero(b);
}

function padZero(str, len) {
    len = len || 2;
    var zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
}