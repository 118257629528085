import React, { useContext, useState } from "react";
import MessagingContext from "../MessagingContext";
import {
    Modal,
    Card,
    List,
    ListItem,
    ListItemText,
    CardHeader,
    CardContent,
    Badge,
    TextField
} from "@material-ui/core";
import ParticipantsDisplay from "../ParticipantsDisplay";
import ConversationDetails from "./ConversationDetails";

export default function(props) {
    const messaging = useContext(MessagingContext);
    const conversation = messaging.getConversationById(
        messaging.selectedConversationId
    );

    const [modalOpen, setModalOpen] = useState(false);

    if (!conversation) {
        return <div></div>;
    }

    const hasUnread = (() => {
        const userTime =
            conversation.participants[messaging.selectedUserId].lastMessage
                .time;

        const lastMessageTime = conversation.latestMessage.time;

        if (parseInt(userTime) < parseInt(lastMessageTime)) {
            return true;
        }
        return false;
    })();

    return (
        <React.Fragment>
            <div
                style={{
                    borderRadius: "5px",
                    // border: "1px solid black",
                    textAlign: "center",
                    cursor: "pointer",
                    fontSize: "12px"
                }}
                onClick={e => {
                    setModalOpen(true);
                }}
            >
                <Badge
                    badgeContent={hasUnread.toString()}
                    variant="dot"
                    color="secondary"
                    invisible={!hasUnread}
                >
                    {conversation.name ? (
                        `(${conversation.userIds.length})${conversation.name}`
                    ) : (
                        <ParticipantsDisplay
                            participantIds={conversation.userIds.filter(
                                userId => {
                                    return userId !== messaging.selectedUserId;
                                }
                            )}
                            avatarStyle={{
                                height: "20px",
                                width: "20px",
                                fontSize: "15px"
                            }}
                        ></ParticipantsDisplay>
                    )}
                </Badge>
            </div>

            <Modal
                disableScrollLock
                disableRestoreFocus
                style={{ display: "flex", alignItems: "center" }}
                open={modalOpen}
                onClose={() => {
                    setModalOpen(false);
                }}
            >
                <ConversationDetails></ConversationDetails>
            </Modal>
        </React.Fragment>
    );
}
