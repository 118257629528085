import React, { useContext } from "react";
import { IconButton, Badge } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import messagingRouterHistory from "../messagingRouterHistory";
import MessagingContext from "../MessagingContext";

export default function(props) {
    const messaging = useContext(MessagingContext);

    const hasUnread = (() => {
        return messaging.conversations.some(conversation => {
            const lastReadTime = parseInt(
                conversation.participants[messaging.selectedUserId].lastMessage
                    .time
            );

            const latestTime = parseInt(conversation.latestMessage.time);

            return lastReadTime < latestTime;
        });
    })();

    return (
        <div style={{ textAlign: "center", fontSize: "12px" }}>
            <div>
                <Badge variant="dot" color="secondary" invisible={!hasUnread}>
                    <div>Conversations</div>
                </Badge>
                <IconButton
                    size="small"
                    onClick={() => {
                        messagingRouterHistory.push("/createConversation");
                    }}
                >
                    <CreateIcon
                        style={{
                            color: "white",
                            height: "15px",
                            width: "15px"
                        }}
                    ></CreateIcon>
                </IconButton>
            </div>
        </div>
    );
}
