import React, { useContext } from "react";
import Row from "./Row/Row";
import { VisitContext } from "./VisitContext";
import usePatientTracking from "./usePatientTracking";
import GlobalContext from "../../../../../hooks/GlobalContext";
import PatientTrackingContext from "./PatientTrackingContext";

export default function(props) {
    const { localStore } = useContext(GlobalContext);
    const patientTracking = useContext(PatientTrackingContext);
    const { stageId } = props;
    const { visits, stages } = patientTracking;

    const sortedVisits = visits
        .filter(visit => {
            const currentStage = getVisitCurrentStage(visit);

            return currentStage.id === stageId;
        })
        .sort((a, b) => {
            return a.createdTime < b.createdTime;
        });

    function getVisitCurrentStage(visit) {
        const stageId =
            visit.stageHistory[visit.stageHistory.length - 1].stageId;
        const myStage = getStageById(stageId);
        return myStage;
    }

    function getStageById(stageId) {
        return stages && stages[stageId]
            ? stages[stageId]
            : {
                  backgroundColor: "grey",
                  id: "zzzzz",
                  name: "???",
                  textColor: "#000000"
              };
    }

    const myStage = getStageById(stageId);
    return (
        <React.Fragment>
            <div>
                {localStore.data.showPatientTrackingStageHeaders && (
                    <div
                        style={{
                            backgroundColor: myStage.backgroundColor,
                            color: myStage.textColor,
                            textAlign: "center",
                            borderBottom: "1px solid black",
                            fontWeight: "bold"
                        }}
                    >
                        {myStage.name}
                    </div>
                )}
                {sortedVisits.map(visit => {
                    return (
                        <VisitContext.Provider key={visit.id} value={visit}>
                            <Row></Row>
                        </VisitContext.Provider>
                    );
                })}
            </div>
        </React.Fragment>
    );
}
