import { createContext, useState, useRef } from "react";

export const localStoreContext = createContext({
    personsColumns: 0,
    updatePersonsColumns: () => {}
});

const defaultLocalData = {
    meaningButtonsColumns: 4,
    meaningButtonsHeight: window.innerHeight / 20,
    volume: 0,
    selectedMessagingUserId: null,
    selectedOfficeId: null,
    personsColumns: 3,
    personsHeight: Math.floor(window.innerHeight / 10),
    personsContainerHeight: Math.floor(window.innerHeight / 10),
    personsContainerEnabled: false,
    roomsColumns: 3,
    roomsHeight: Math.floor(window.innerHeight / 5),
    messagesHeight: Math.floor(window.innerHeight / 10),
    patientTrackingHeight: Math.floor(window.innerHeight / 5),
    personColorsAlwaysVisible: false,
    patientTrackingSoundEnabled: false,
    pagingSoundEnabled: true,
    messagingSoundEnabled: false
};

export const storageKeys = {
    volume: "volume",
    selectedMessagingUserId: "selectedMessagingUserId",
    selectedOfficeId: "selectedOfficeId",
    personsColumns: "personsColumns",
    personsHeight: "personsHeight",
    personsContainerHeight: "personsContainerHeight",
    personsContainerEnabled: "personsContainerEnabled",
    roomsColumns: "roomsColumns",
    roomsHeight: "roomsHeight",
    messagesHeight: "messagesHeight",
    meaningButtonsColumns: "meaningButtonsColumns",
    meaningButtonsHeight: "meaningButtonsHeight",
    patientTrackingHeight: "patientTrackingHeight",
    showPatientTrackingStageHeaders: "showPatientTrackingStageHeaders",
    personColorsAlwaysVisible: "personColorsAlwaysVisible",

    patientTrackingSoundEnabled: "patientTrackingSoundEnabled",
    pagingSoundEnabled: "pagingSoundEnabled",
    messagingSoundEnabled: "messagingSoundEnabled"
};
export default function useLocalStore() {
    const localData = { ...defaultLocalData, ...getLocalStorageData() };
    const [data, setData] = useState({ ...localData });

    function update(key, val) {
        if (Object.keys(storageKeys).includes(key)) {
            setData({ ...data, [key]: val });
            updateLocalStorageData({ [key]: val });
        }
    }

    return {
        data,
        update,
        storageKeys
    };
}

function getLocalStorageData() {
    const insyncAppJson = window.localStorage.getItem("insyncapp");
    let localData;
    try {
        localData = JSON.parse(insyncAppJson);
    } catch (e) {
        console.error("local storage data not loaded");
    }

    return localData;
}

function updateLocalStorageData(newData) {
    const localStorageData = getLocalStorageData();
    const json = JSON.stringify({ ...localStorageData, ...newData });
    window.localStorage.setItem("insyncapp", json);
}
