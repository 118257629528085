import React, { useContext, useState, useEffect } from "react";
import {
    Modal,
    Card,
    CardContent,
    Button,
    TextField,
    CircularProgress
} from "@material-ui/core";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import { VisitContext } from "../../VisitContext";
import updateVisitTimes from "../../httpsCallable/updateVisitTimes";
import GlobalContext from "../../../../../../../hooks/GlobalContext";

export default function(props) {
    const { open, onClose } = props;
    const visit = useContext(VisitContext);
    const { mainCtx } = useContext(GlobalContext);
    const { officeId } = mainCtx;
    const [appointmentTime, setAppointmentTime] = useState(
        visit.appointmentTime
    );
    const [arrivalTime, setArrivalTime] = useState(visit.arrivalTime);
    const [appointmentLength, setAppointmentLength] = useState(
        Math.floor((visit.dueOutTime - visit.appointmentTime) / 60000)
    );
    const [loading, setLoading] = useState(false);

    function reset() {
        setAppointmentTime(visit.appointmentTime);
        setArrivalTime(visit.arrivalTime);
        setAppointmentLength(
            Math.floor((visit.dueOutTime - visit.appointmentTime) / 60000)
        );
    }

    useEffect(() => {
        reset();
    }, [open]);

    return (
        <Modal
            open={open}
            onClose={onClose}
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <Card>
                <CardContent>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <div>
                            <KeyboardTimePicker
                                ampm={true}
                                fullWidth
                                margin="normal"
                                id="arrival-time picker"
                                label="Arrival Time"
                                value={arrivalTime}
                                onChange={e => {
                                    const time = e ? e.valueOf() : Date.now();
                                    setArrivalTime(time);
                                }}
                            />
                        </div>
                        <div>
                            <KeyboardTimePicker
                                ampm={true}
                                fullWidth
                                margin="normal"
                                id="appointment-time picker"
                                label="Appointment Time"
                                value={appointmentTime}
                                onChange={e => {
                                    const time = e ? e.valueOf() : Date.now();
                                    setAppointmentTime(time);
                                }}
                            />
                        </div>
                        <div>
                            <TextField
                                label="Appointment Length (Minutes)"
                                type="number"
                                inputProps={{ min: 0 }}
                                value={appointmentLength}
                                onChange={e => {
                                    setAppointmentLength(e.target.value);
                                }}
                            ></TextField>
                        </div>
                        <div>
                            <KeyboardTimePicker
                                ampm={true}
                                fullWidth
                                margin="normal"
                                id="due-out-time picker"
                                label="Due Out Time"
                                value={
                                    appointmentTime + appointmentLength * 60000
                                }
                                onChange={e => {
                                    const time = e ? e.valueOf() : Date.now();
                                    setAppointmentLength(
                                        Math.floor(
                                            (time - appointmentTime) / 60000
                                        )
                                    );
                                }}
                            />
                        </div>
                        <div>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    //update visit
                                    setLoading(true);
                                    updateVisitTimes({
                                        officeId: officeId,
                                        visitId: visit.id,
                                        arrivalTime,
                                        appointmentTime,
                                        dueOutTime:
                                            appointmentTime +
                                            appointmentLength * 60000
                                    })
                                        .then(result => {
                                            setLoading(false);
                                            onClose();
                                            console.log(result);
                                        })
                                        .catch(err => {
                                            setLoading(false);
                                            console.error(err);
                                        });
                                }}
                            >
                                Update Times
                            </Button>
                            {loading && <CircularProgress></CircularProgress>}
                        </div>
                    </MuiPickersUtilsProvider>
                </CardContent>
            </Card>
        </Modal>
    );
}
