import React, { useContext, useState } from "react";
import {
    Modal,
    Card,
    CardContent,
    Button,
    Menu,
    MenuItem,
    CircularProgress
} from "@material-ui/core";
import PatientTrackingContext from "../../PatientTrackingContext";
import moveToStage from "../../httpsCallable/moveToStage";
import { VisitContext } from "../../VisitContext";
import GlobalContext from "../../../../../../../hooks/GlobalContext";
import StageSelector from "./StageSelector";

export default function(props) {
    const { open, onClose, stageId } = props;
    const { stages } = useContext(PatientTrackingContext);

    return (
        <Modal
            open={open}
            onClose={onClose}
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <Card>
                <CardContent>
                    <StageSelector
                        onClose={onClose}
                        stageId={stageId}
                    ></StageSelector>
                </CardContent>
            </Card>
        </Modal>
    );
}
