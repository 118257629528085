import { useEffect, useContext } from "react";

import { useDocListener } from "./docListener";
import GlobalContext from "../../../../../hooks/GlobalContext";

const defaultColumnLayout = {
    Name: { order: 2, name: "Name", id: "Name", enabled: true },
    Room: { id: "Room", enabled: true, order: 3, name: "Room" },
    Stage: { id: "Stage", enabled: false, order: 3, name: "Stage" },
    ArrivalTime: {
        order: 1,
        name: "Arrival Time",
        id: "ArrivalTime",
        enabled: true
    },
    AppointmentTime: {
        order: 3,
        name: "Appointment Time",
        id: "AppointmentTime",
        enabled: true
    },
    TimeElapsed: {
        id: "TimeElapsed",
        enabled: false,
        order: 3,
        name: "Time Elapsed"
    },
    TimeRemaining: {
        order: 1,
        name: "Time Remaining",
        id: "TimeRemaining",
        enabled: true
    }
};

export default function(props) {
    const { mainCtx } = useContext(GlobalContext);
    const { officeId } = mainCtx;
    const patientTrackingDocListener = useDocListener();

    useEffect(() => {
        console.log(`/offices/${officeId}/app/patientTracking`);
        patientTrackingDocListener.subscribe(
            `/offices/${officeId}/app/patientTracking`,
            []
        );
    }, [officeId]);

    const getOrderedColumnIds = trackerColumns => {
        return Object.keys(trackerColumns)
            .filter(columnId => {
                const column = trackerColumns[columnId];
                return column.enabled;
            })
            .sort((idA, idB) => {
                const columnA = trackerColumns[idA];
                const columnB = trackerColumns[idB];
                return columnA.order - columnB.order;
            });
    };

    const columnLayout = (() => {
        let result = [];
        if (
            !patientTrackingDocListener.data ||
            !patientTrackingDocListener.data.trackerColumns
        ) {
            result = getOrderedColumnIds(defaultColumnLayout);
        } else {
            result = getOrderedColumnIds(
                patientTrackingDocListener.data.trackerColumns
            );
        }

        return result;
    })();

    const rooms =
        patientTrackingDocListener.data && patientTrackingDocListener.data.rooms
            ? patientTrackingDocListener.data.rooms
            : [];

    const stages =
        patientTrackingDocListener.data &&
        patientTrackingDocListener.data.stages
            ? patientTrackingDocListener.data.stages
            : [];

    const visitSettings =
        patientTrackingDocListener.data &&
        patientTrackingDocListener.data.visitSettings
            ? patientTrackingDocListener.data.visitSettings
            : null;

    const defaults =
        patientTrackingDocListener.data &&
        patientTrackingDocListener.data.defaults
            ? patientTrackingDocListener.data.defaults
            : null;

    return {
        rooms: rooms,
        stages: stages,
        columnLayout: columnLayout,
        visitSettings: visitSettings,
        defaults: defaults
    };
}
