import firebase from "../../../../../../firebase/firebase";

export default function({ officeId, visitId, name }) {
    const data = { officeId, visitId, name };

    return firebase.functions().httpsCallable("allFunctions")({
        funcRole: "patientTrackingUser",
        funcName: "updatePatientName",
        data: data
    });
}
