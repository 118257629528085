import React, { useContext } from "react";
import Room from "./Room/Room";

import GlobalContext from "../../../../../../hooks/GlobalContext";
import { Resizable } from "re-resizable";
export default function(props) {
    const rooms = props.rooms;
    const { localStore } = useContext(GlobalContext);
    return (
        <Resizable
            style={{ display: "flex" }}
            defaultSize={{ height: `${localStore.data.roomsHeight}px` }}
            enable={{bottom: true}}
            size={{height: `${localStore.data.roomsHeight}px`}}
            onResizeStop={(event, direction, refToElement, delta) => {
                const newHeight = parseInt(refToElement.style.height);
                localStore.update(
                    localStore.storageKeys.roomsHeight,
                    newHeight
                );
            }}
        >
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: `repeat(${localStore.data.roomsColumns}, minmax(0,1fr))`,
                    gridAutoRows: "1fr",
                    gridGap: "1",
                    overflowY: "auto",
                    overflowX: "hidden",
                    width: "100%"
                }}
            >
                {getSortedRoomIds(rooms).map(roomId => {
                    return (
                        <Room
                            key={roomId}
                            room={{ ...rooms[roomId], roomId }}
                        />
                    );
                })}
            </div>
        </Resizable>
    );
}

function getSortedRoomIds(rooms) {
    return Object.keys(rooms)
        .filter(roomId => {
            return Object.keys(rooms).includes(roomId);
        })
        .sort((a, b) => {
            if (rooms[a].name > rooms[b].name) {
                return 1;
            }
            if (rooms[a].name < rooms[b].name) {
                return -1;
            }
            return 0;
        })
        .sort((a, b) => {
            const roomA = { ...rooms[a] };
            const roomB = { ...rooms[b] };
            roomA.listPosition = roomA.listPosition ? roomA.listPosition : 0;
            roomB.listPosition = roomB.listPosition ? roomB.listPosition : 0;

            return roomA.listPosition - roomB.listPosition;
        });
}
