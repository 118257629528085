import { createContext, useState, useRef } from "react";
import firebase from "../../../../../firebase/firebase";

export const queryContext = createContext({
    data: null,
    subscribe: () => {},
    unsubscribe: () => {}
});

export const useQueryListener = () => {
    const [data, setData] = useState([]);
    const [listenError, setListenError] = useState(null);
    const [loading, setLoading] = useState(false);
    const unsubscribeRef = useRef(null);

    const subscribe = (path, conditions) => {
        setLoading(true);
        setListenError(null);
        console.log(`subscribing to ${path}`);
        // console.log({ path, conditions });
        unsubscribe();
        let queryRef = firebase.firestore().collection(path);
        conditions.forEach(condition => {
            if (condition[0] !== null && condition[2] !== null)
                if (condition[0] === "where") {
                    queryRef = queryRef.where(
                        condition[1],
                        condition[2],
                        condition[3]
                    );
                }
            if (condition[0] === "limit") {
                queryRef = queryRef.limit(condition[1]);
            }
            if (condition[0] === "orderBy") {
                queryRef = queryRef.orderBy(condition[1], condition[2]);
            }
        });
        unsubscribeRef.current = queryRef.onSnapshot(
            querySnapshot => {
                const items = [];
                querySnapshot.forEach(doc => {
                    items.push(doc.data());
                });
                // console.log(items);
                setData(items);
                setLoading(false);
            },
            err => {
                console.error(err);
                setListenError(err);
                setLoading(false);
            }
        );
    };

    const unsubscribe = () => {
        setData([]);
        unsubscribeRef.current && unsubscribeRef.current();
    };

    return { data, subscribe, unsubscribe, listenError, loading, setLoading };
};
