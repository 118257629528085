import React, { useContext, useState } from "react";
import messagingRouterHistory from "./messagingRouterHistory";
import MessagingContext from "./MessagingContext";
import createConversation from "../../../../../firebase/httpsCallable/createConversation";
import GlobalContext from "../../../../../hooks/GlobalContext";

import ConversationListItem from "./ConversationListItem";
import {
    Select,
    MenuItem,
    TextField,
    Button,
    Card,
    NativeSelect,
    InputLabel,
    ListItemText
} from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";

export default function(props) {
    const { mainCtx } = useContext(GlobalContext);
    const messaging = useContext(MessagingContext);
    const [selectedUserIds, setSelectedUserIds] = useState([]);

    function arraysEqual(a, b) {
        if (a.length !== b.length) {
            return false;
        }
        for (var i = 0; i < a.length; i++) {
            if (a[i] !== b[i]) {
                return false;
            }
        }
        return true;
    }

    function getSortedUserIds(userIds) {
        return Array.from(new Set(userIds)).sort();
    }

    const existingConversations = messaging.conversations.filter(
        conversation => {
            return arraysEqual(
                getSortedUserIds(conversation.userIds),
                getSortedUserIds([...selectedUserIds, messaging.selectedUserId])
            );
        }
    );

    function create() {
        const participants = getSortedUserIds([
            ...selectedUserIds,
            messaging.selectedUserId
        ]);
        const req = {
            officeId: mainCtx.officeId,
            userIds: participants
        };
        createConversation(req).then(result => {
            messaging.selectConversationId(result.data.id);
            messagingRouterHistory.push("/conversation");
        });
    }
    return (
        <div style={{ overflowY: "auto", overflowX: "hidden", width: "100%" }}>
            <InputLabel id="select-participants-label">Participants</InputLabel>
            <Select
                style={{ maxWidth: "100%", fontSize: "12px" }}
                labelId="select-participants-label"
                id="select-participants"
                multiple
                value={selectedUserIds}
                onChange={e => {
                    setSelectedUserIds(e.target.value);
                }}
                renderValue={value => {
                    return value
                        .map(userId => {
                            return messaging.getUserById(userId).name;
                        })
                        .join(",");
                }}
            >
                {messaging.users
                    .filter(user => {
                        return user.id !== messaging.selectedUserId;
                    })
                    .map(user => {
                        return (
                            <MenuItem key={user.id} value={user.id}>
                                {user.passwordProtected ? (
                                    <LockIcon
                                        style={{
                                            height: "20px",
                                            width: "20px",
                                            padding: "2px"
                                        }}
                                    ></LockIcon>
                                ) : (
                                    <LockOpenIcon
                                        style={{
                                            height: "20px",
                                            width: "20px",
                                            padding: "2px"
                                        }}
                                    ></LockOpenIcon>
                                )}
                                <ListItemText
                                    disableTypography
                                    style={{ fontSize: "12px" }}
                                    primary={user.name}
                                ></ListItemText>
                            </MenuItem>
                        );
                    })}
            </Select>
            {existingConversations.length > 0 && (
                <div>
                    <div>
                        existing conversations ({existingConversations.length})
                    </div>
                    <Card
                        style={{
                            // height: "200px",
                            overflowY: "scroll",
                            border: "1px solid black"
                        }}
                    >
                        {existingConversations.map(conversation => {
                            return (
                                <ConversationListItem
                                    key={conversation.id}
                                    conversation={conversation}
                                ></ConversationListItem>
                            );
                        })}
                    </Card>
                </div>
            )}

            {selectedUserIds.length > 0 && (
                <div>
                    <Button size="small" variant="contained" onClick={create}>
                        create {existingConversations.length > 0 && "new"}
                    </Button>
                </div>
            )}
        </div>
    );
}
