import React from "react";
import { AppBar, Toolbar, useTheme } from "@material-ui/core";
import HamburgerMenu from "./HamburgerMenu";
import DisplayButton from "./DisplayButton";
import VolumeControl from "./VolumeControl";
import SupportButton from "./SupportButton";
import PortalButton from "./PortalButton";
import SoundButton from "./SoundButton"

export default function(props) {
    return (
        <AppBar position="static" style={{ width: "100%", margin: 0 }}>
            <Toolbar variant="dense" disableGutters style={{ minHeight: 0 }}>
                <HamburgerMenu />
                <DisplayButton></DisplayButton>
                {/* <VolumeControl></VolumeControl> */}
                <SoundButton></SoundButton>
                <SupportButton></SupportButton>
                <PortalButton></PortalButton>
        
            </Toolbar>
        </AppBar>
    );
}
