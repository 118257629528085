import { createContext, useState, useRef } from "react";
import firebase from "../firebase";

export const PatientTrackingDocContext = createContext({
    patients: {},
    rooms: {},
    stages: {},
    visits: {},
    subscribe: () => {},
    unsubscribe: () => {}
});

export const usePatientTrackingDoc = () => {
    const [patients, setPatients] = useState({});
    const [rooms, setRooms] = useState({});
    const [stages, setStages] = useState({});
    const [visits, setVisits] = useState({});
    const [defaults, setDefaults] = useState({});
    const [visitDisplaySettings, setVisitDisplaySettings] = useState({});
    const [visitSettings, setVisitSettings] = useState({});
    const [listenError, setListenError] = useState(null);
    const [loading, setLoading] = useState(false);
    const unsubscribeRef = useRef(null);

    const subscribe = officeId => {
        // console.log("subscribing to patientTracking");
        setLoading(true);
        setListenError(null);
        unsubscribe();
        unsubscribeRef.current = firebase
            .firestore()
            .doc(`/offices/${officeId}/app/patientTracking`)
            .onSnapshot(
                doc => {
                    const data = doc.data();
                    data.patients
                        ? setPatients(data.patients)
                        : setPatients({});
                    data.rooms ? setRooms(data.rooms) : setRooms({});
                    data.stages ? setStages(data.stages) : setStages({});
                    data.visits ? setVisits(data.visits) : setVisits({});
                    data.defaults
                        ? setDefaults(data.defaults)
                        : setDefaults({});
                    data.visitDisplaySettings
                        ? setVisitDisplaySettings(data.visitDisplaySettings)
                        : setVisitDisplaySettings({});
                    data.visitSettings
                        ? setVisitSettings(data.visitSettings)
                        : setVisitSettings({});
                    setLoading(false);
                },
                err => {
                    setLoading(false);
                    setListenError(err);
                }
            );
    };

    const unsubscribe = () => {
        setRooms({});
        setStages({});
        setVisits({});
        setDefaults({});
        setVisitDisplaySettings({});
        setVisitSettings({});
        unsubscribeRef.current && unsubscribeRef.current();
    };

    return {
        patients,
        rooms,
        stages,
        visits,
        subscribe,
        unsubscribe,
        listenError,
        setPatients,
        setRooms,
        setStages,
        setVisits,
        defaults,
        visitDisplaySettings,
        visitSettings,
        loading
    };
};
