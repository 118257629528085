import React, { useContext, useState } from "react";
import PatientTrackingContext from "../../PatientTrackingContext";
import { Chip } from "@material-ui/core";
import StageModal from "../Modals/StageModal";

export default function(props) {
    const { stageId, style } = props;
    const { stages } = useContext(PatientTrackingContext);
    const [modalOpen, setModalOpen] = useState(false);
    const existingStage = stages[stageId];

    const stageName = existingStage ? existingStage.name : "??";

    return (
        <React.Fragment>
            <div
                style={{ ...style }}
                onClick={() => {
                    setModalOpen(true);
                }}
            >
                {/* <Chip
                    size="small"
                    style={{
                        padding: 0,
                        margin: 0,
                        maxWidth: "100%",
                        cursor: "pointer"
                    }}
                    label={stageName.toString()}
                ></Chip> */}
                {stageName.toString()}
            </div>
            <StageModal
                stageId={stageId}
                open={modalOpen}
                onClose={() => {
                    setModalOpen(false);
                }}
            ></StageModal>
        </React.Fragment>
    );
}
