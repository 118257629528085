import firebase from "../../../../../../firebase/firebase";

export default function({
    officeId,
    visitId,
    arrivalTime,
    appointmentTime,
    dueOutTime
}) {
    const data = {
        officeId,
        visitId,
        arrivalTime,
        appointmentTime,
        dueOutTime
    };

    return firebase.functions().httpsCallable("allFunctions")({
        funcRole: "patientTrackingUser",
        funcName: "updateVisitTimes",
        data: data
    });
}
