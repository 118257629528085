import React from "react";
import { Modal, Card, CardContent } from "@material-ui/core";

import StageSelector from "./StageSelector";
import RoomSelector from "./RoomSelector";

export default function(props) {
    const { open, onClose } = props;

    return (
        <Modal
            open={open}
            onClose={onClose}
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <Card>
                <CardContent>
                    <div>
                        <RoomSelector onClose={onClose}></RoomSelector>
                    </div>
                    <div>
                        <StageSelector onClose={onClose}></StageSelector>
                    </div>
                </CardContent>
            </Card>
        </Modal>
    );
}
