import React, { useContext, useState } from "react";
import PatientTrackingContext from "../../PatientTrackingContext";
import { VisitContext } from "../../VisitContext";

import EndVisitModal from "../Modals/EndVisitModal";

export default function(props) {
    const { style } = props;
    const visit = useContext(VisitContext);
    const [modalOpen, setModalOpen] = useState(false);
    const { patientTrackingDoc } = useContext(PatientTrackingContext);


    const countFromTime = (() => {
        return patientTrackingDoc.visitSettings &&
            patientTrackingDoc.visitSettings.countFrom &&
            patientTrackingDoc.visitSettings.countFrom === "appointmentTime"
            ? visit.appointmentTime
            : visit.arrivalTime;
    })();

 
    const timeString = Math.floor(
        (Date.now() - parseInt(countFromTime)) / 60000
    ).toString();

    return (
        <React.Fragment>
            <div
                style={{ ...style, cursor: "pointer" }}
                onClick={() => {
                    setModalOpen(true);
                }}
            >
                {timeString}
            </div>
            <EndVisitModal
                open={modalOpen}
                onClose={() => {
                    setModalOpen(false);
                }}
            ></EndVisitModal>
        </React.Fragment>
    );
}
