import React, { useContext } from "react";
import { AppBar, Toolbar, IconButton, Tooltip } from "@material-ui/core";

import CreateVisit from "./CreateVisit";
import GlobalContext from "../../../../../hooks/GlobalContext";
import ViewStreamIcon from "@material-ui/icons/ViewStream";

export default function(props) {
    const { mainCtx, localStore } = useContext(GlobalContext);
    return (
        <React.Fragment>
            <AppBar
                position="relative"
                style={{ minHeight: "0px", padding: "0px" }}
            >
                <Toolbar disableGutters style={{ minHeight: "0px" }}>
                    <CreateVisit></CreateVisit>
                    <Tooltip
                        title="toggle headers"
                        PopperProps={{ disablePortal: true }}
                    >
                        <IconButton
                            size="small"
                            onClick={() => {
                                localStore.update(
                                    localStore.storageKeys
                                        .showPatientTrackingStageHeaders,
                                    !localStore.data
                                        .showPatientTrackingStageHeaders
                                );
                            }}
                        >
                            <ViewStreamIcon></ViewStreamIcon>
                        </IconButton>
                    </Tooltip>
                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
}
