import React, { useContext } from "react";
import Persons from "./Persons/Persons";
import Rooms from "./Rooms/Rooms";
import { Divider, Card, CardContent } from "@material-ui/core";
import GlobalContext from "../../../../../hooks/GlobalContext";
import Meaning from "./Meaning/Meaning";
import PagingEventHandler from "./PagingEventHandler";

export default function(props) {
    const { pagingDoc } = useContext(GlobalContext);
    const persons = pagingDoc.persons;
    const rooms = pagingDoc.rooms;
    PagingEventHandler(pagingDoc);
    
    if (pagingDoc.listenError) {
        return (
            <Card>
                <CardContent>
                    <div>Failed to connect to paging.</div>
                    <div>Your license may have been taken by another user.</div>
                </CardContent>
            </Card>
        );
    }

    return (
        <div>
            <Persons persons={persons} />
            <Divider></Divider>
            <Meaning></Meaning>
            <Divider></Divider>
            <Rooms rooms={rooms} />
        </div>
    );
}
