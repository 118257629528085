import React, { useContext, useState } from "react";
import { Chip } from "@material-ui/core";
import { VisitContext } from "../../VisitContext";
import NameModal from "../Modals/NameModal";

export default function(props) {
    const { style } = props;
    const visit = useContext(VisitContext);
    const [modalOpen, setModalOpen] = useState(false);
    return (
        <React.Fragment>
            <div
                style={{ ...style, cursor: "pointer" }}
                onClick={() => {
                    setModalOpen(true);
                }}
            >
                {/* <Chip
                    size="small"
                    style={{
                        padding: 0,
                        margin: 0,
                        maxWidth: "100%",
                        cursor: "pointer"
                    }}
                    label={visit.name.toString()}
                ></Chip> */}
                {visit.name.toString()}
            </div>
            <NameModal
                open={modalOpen}
                onClose={() => {
                    setModalOpen(false);
                }}
            ></NameModal>
        </React.Fragment>
    );
}
