import React, { useContext, useState, useEffect } from "react";
import {
    Modal,
    Card,
    CardContent,
    Button,
    TextField,
    CircularProgress
} from "@material-ui/core";
import { VisitContext } from "../../VisitContext";
import updatePatientName from "../../httpsCallable/updatePatientName";
import GlobalContext from "../../../../../../../hooks/GlobalContext";

export default function(props) {
    const { open, onClose } = props;
    const visit = useContext(VisitContext);
    const { mainCtx } = useContext(GlobalContext);
    const { officeId } = mainCtx;
    const [name, setName] = useState(visit.name);
    const [loading, setLoading] = useState(false);

    function reset() {
        setName(visit.name);
    }
    useEffect(() => {
        reset();
    }, [open]);

    return (
        <Modal
            open={open}
            onClose={onClose}
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <Card>
                <CardContent>
                    <div>
                        <TextField
                            value={name}
                            onChange={e => {
                                setName(e.target.value);
                            }}
                        ></TextField>
                    </div>
                    <div>
                        <Button
                            variant="contained"
                            onClick={() => {
                                //updateName
                                setLoading(true);
                                updatePatientName({
                                    officeId: officeId,
                                    visitId: visit.id,
                                    name
                                })
                                    .then(result => {
                                        setLoading(false);
                                        onClose();
                                        console.log(result);
                                    })
                                    .catch(err => {
                                        setLoading(false);
                                        console.error(err);
                                    });
                            }}
                        >
                            Update Name
                        </Button>
                        {loading && <CircularProgress></CircularProgress>}
                    </div>
                </CardContent>
            </Card>
        </Modal>
    );
}
