import React, { useContext } from "react";
import Person from "./Person";
import GlobalContext from "../../../../../../hooks/GlobalContext";
import { Resizable } from "re-resizable";

export default function(props) {
    const persons = props.persons;
    const { localStore } = useContext(GlobalContext);
    
    const containerStyle = (() => {
        let style = {};
        if (localStore.data.personsContainerEnabled) {
            style.overflowY = "auto";
            style.overflowX = "hidden";
        } else {
            style.display = "flex";
        }
        return style;
    })();

    return (
        <Resizable
            style={containerStyle}
            defaultSize={{ height: `${localStore.data.personsHeight}px` }}
            size={{height: `${localStore.data.personsHeight}px`}}
            onResizeStop={(event, direction, refToElement, delta) => {
                const newHeight = parseInt(refToElement.style.height);
                localStore.update(
                    localStore.storageKeys.personsHeight,
                    newHeight
                );
            }}
            enable={{ bottom: true }}
        >
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: `repeat(${localStore.data.personsColumns}, minmax(0,1fr))`,
                    overflowY: "hidden",
                    overflowX: "hidden",
                    width: "100%"
                }}
            >
                {getSortedPersonIds(persons).map(personId => {
                    return (
                        <Person
                            key={personId}
                            person={{ ...persons[personId], personId }}
                        />
                    );
                })}
            </div>
        </Resizable>
    );
}

function getSortedPersonIds(persons) {
    const sorted = Object.keys(persons)
        .sort((a, b) => {
            if (persons[a].name > persons[b].name) {
                return 1;
            }
            if (persons[a].name < persons[b].name) {
                return -1;
            }
            return 0;
        })
        .sort((a, b) => {
            const personA = { ...persons[a] };
            const personB = { ...persons[b] };
            personA.listPosition = personA.listPosition
                ? personA.listPosition
                : 0;
            personB.listPosition = personB.listPosition
                ? personB.listPosition
                : 0;
            return personA.listPosition - personB.listPosition;
        });

    return sorted;
}
