import React, {useState} from "react";
import { IconButton, Menu, Tooltip } from "@material-ui/core";

import VisibilityIcon from "@material-ui/icons/Visibility";
import DisplayMenu from "./DisplayMenu";

export default function DisplayButton(props) {
    const [anchorEl, setAnchorEl] = useState(null);

    return (
        <React.Fragment>
            <Tooltip title="Display Settings">
                <IconButton
                    size="small"
                    onClick={e => {
                        setAnchorEl(e.currentTarget);
                    }}
                >
                    <VisibilityIcon style={{ color: "white" }} />
                </IconButton>
            </Tooltip>
            <Menu
                disableScrollLock
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => {
                    setAnchorEl(null);
                }}
                // BackdropProps={{ style: { width: "100%" } }}
            >
                <DisplayMenu></DisplayMenu>
            </Menu>
        </React.Fragment>
    );
}
