import React, { useState, useContext } from "react";
import moveToStage from "../../httpsCallable/moveToStage";
import { VisitContext } from "../../VisitContext";
import GlobalContext from "../../../../../../../hooks/GlobalContext";
import PatientTrackingContext from "../../PatientTrackingContext";
import { Menu, Button, MenuItem, CircularProgress } from "@material-ui/core";

export default function(props) {
    const { onClose } = props;
    const { stages } = useContext(PatientTrackingContext);
    const visit = useContext(VisitContext);
    const { mainCtx } = useContext(GlobalContext);
    const { officeId } = mainCtx;
    const [anchorEl, setAnchorEl] = useState(null);
    const [loading, setLoading] = useState(false);
    const latestStage = visit.stageHistory[visit.stageHistory.length - 1];
    const stageId = latestStage.stageId;
    const currentStage =
        stages && stages[stageId]
            ? stages[stageId]
            : {
                  id: "??",
                  name: "??",
                  backgroundColor: "grey",
                  textColor: "white"
              };

    const orderedStages = Object.keys(stages)
        .sort((idA, idB) => {
            const nameA =
                stages[idA] && stages[idA].name ? stages[idA].name : "zzz";
            const nameB =
                stages[idB] && stages[idB].name ? stages[idB].name : "zzz";

            return nameA.localeCompare(nameB);
        })
        .map(id => {
            return stages[id];
        });

    return (
        <React.Fragment>
            <Button
                variant="contained"
                onClick={e => {
                    setAnchorEl(e.target);
                }}
                style={{
                    backgroundColor: currentStage.backgroundColor,
                    color: currentStage.textColor
                }}
            >
                {currentStage.name}
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => {
                    setAnchorEl(null);
                }}
            >
                {orderedStages.map((stage, index) => {
                    return (
                        <MenuItem
                            key={stage.id + index}
                            onClick={() => {
                                setLoading(true);
                                const req = {
                                    officeId: officeId,
                                    visitId: visit.id,
                                    stageId: stage.id
                                };
                                console.log(req);
                                moveToStage(req)
                                    .then(() => {
                                        setLoading(false);
                                        setAnchorEl(null);
                                        onClose();
                                    })
                                    .catch(err => {
                                        setLoading(false);
                                        console.error(err);
                                    });
                            }}
                            key={stage.id}
                            style={{
                                backgroundColor: stage.backgroundColor,
                                color: stage.textColor
                            }}
                        >
                            {stage.name}
                        </MenuItem>
                    );
                })}
            </Menu>
            {loading && <CircularProgress></CircularProgress>}
        </React.Fragment>
    );
}
