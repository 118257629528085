import React, { useContext } from "react";
import MessagingContext from "./MessagingContext";
import messagingRouterHistory from "./messagingRouterHistory";

import ConversationListItem from "./ConversationListItem";
import { Paper } from "@material-ui/core";

export default function(props) {
    const { conversations, conversationQueryListener } = useContext(
        MessagingContext
    );

    return (
        <Paper
            style={{
                border: "1px solid black",
                overflowY: "scroll"
            }}
        >
            {conversationQueryListener.loading && `LOADING...`}
            {conversations
                .sort((a, b) => {
                    const atime = parseInt(a.latestMessage.time);
                    const btime = parseInt(b.latestMessage.time);
                    return btime - atime;
                })
                .map(conversation => {
                    return (
                        <ConversationListItem
                            key={conversation.id}
                            conversation={conversation}
                        ></ConversationListItem>
                    );
                })}
        </Paper>
    );
}
